import { Popover } from '@progress/kendo-react-tooltip';
import { ReactElement, useEffect, useRef } from 'react';
import { useFittingPopoverCallout } from '../../hooks/commonHooks';
import { domService } from '../../services/domService';
import { DivButton } from '../common/DivButton';
import { PopupPropsNestedContextProvider } from '../common/popupPropsNestedContextProvider';

export function HintsDefaultLayout({ hints, title }: { hints: string[]; title?: string }) {
    return (
        <div className="formatted-content-area light-dfn-area">
            {title && <div className="formatted-content">{title}</div>}
            <ul>
                {hints.map((p, pi) => (
                    <li key={pi} dangerouslySetInnerHTML={{ __html: p }} />
                ))}
            </ul>
        </div>
    );
}

export function Hints({ show, onToggleHints, hints }: { show?: boolean; onToggleHints?: () => void; hints?: ReactElement }) {
    const hintsButtonRef = useRef<HTMLDivElement>(null);
    const hintsWrapperRef = useRef<HTMLDivElement>(null);
    const [popoverId, onPopoverPosition] = useFittingPopoverCallout('horizontal', hintsButtonRef.current);

    useEffect(() => {
        if (!show) return;

        function ensureHintsInViewport() {
            if (!hintsWrapperRef.current) return;
            const hintsPopupWrapper = hintsWrapperRef.current.closest<HTMLElement>('.k-animation-container');
            if (!hintsPopupWrapper) return;
            domService.scrollIntoViewIfNeeded(hintsPopupWrapper, false, 'auto');
        }

        ensureHintsInViewport();
    }, [show]);

    if (!hints) return null;

    return (
        <>
            <DivButton ref={hintsButtonRef} fillMode="link" themeColor="secondary" size="small" onClick={onToggleHints} className="-minw0">
                Hints
            </DivButton>
            <PopupPropsNestedContextProvider
                value={p => ({
                    ...p,
                    anchorAlign: { horizontal: 'left', vertical: 'bottom' },
                    popupAlign: { horizontal: 'left', vertical: 'top' }
                })}
            >
                <Popover
                    id={popoverId}
                    anchor={hintsButtonRef.current}
                    position="bottom"
                    popoverClass="k-icp-tooltip-popover hints-tooltip-popover !k-px-1 !k-py-3"
                    className="k-max-w-full"
                    show={show}
                    margin={{ vertical: 6, horizontal: 0 }}
                    onPosition={onPopoverPosition}
                >
                    <div ref={hintsWrapperRef} onMouseDown={e => e.preventDefault()}>
                        {hints}
                    </div>
                </Popover>
            </PopupPropsNestedContextProvider>
        </>
    );
}
