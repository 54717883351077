import { Reveal } from '@progress/kendo-react-animation';
import { Button } from '@progress/kendo-react-buttons';
import { StackLayout } from '@progress/kendo-react-layout';
import { useState } from 'react';
import { To } from 'react-router-dom';
import { useGlobalCanvas } from '../../../../hooks/canvasHooks';
import { ReactComponent as ExpandIcon } from '../../../../icons/chevron-down.svg';
import { ReactComponent as CollapseIcon } from '../../../../icons/chevron-up.svg';
import { BoxType, canvasService } from '../../../../services/canvasService';
import { WithNotNullable } from '../../../../services/common/common.types';
import { JourneyTaskRelatedItem, SecondaryBox } from '../../../../services/journeyService';
import CanvasBox, { TaskRelatedEmptyItemsView } from '../../../canvas/box';
import LoadingIndicator from '../../../ui/loadingIndicator';
import { H3 } from '../../../ui/typography';

type RelatedCanvasBoxesProps = {
    boxes: SecondaryBox[];
    navigate: (to: To) => void;
    showItemsOrRelatedItemsTo?: JourneyTaskRelatedItem[];
    pointToBoxOnNavigate?: BoxType;
    variant?: 'panel' | 'default';
};

export function RelatedCanvasBoxes({ boxes, navigate, showItemsOrRelatedItemsTo, pointToBoxOnNavigate, variant = 'default' }: RelatedCanvasBoxesProps) {
    const { canvas, relatableItems } = useGlobalCanvas();
    const [isCollapsed, setIsCollapsed] = useState(false);

    const viewBoxesInCanvas = () => {
        const queryParameters = new URLSearchParams();
        if (pointToBoxOnNavigate) {
            queryParameters.append('canvas-target-box', pointToBoxOnNavigate);
            boxes.forEach(b => queryParameters.append('canvas-source-box', b.type));
        } else
            boxes
                .map(box => canvasService.getBoxMetadata(box.type)?.highlight)
                .forEach(boxHighlight => {
                    if (!boxHighlight) return;
                    queryParameters.append('highlight', boxHighlight);
                });

        const queryString = queryParameters.toString();
        navigate(`../canvas${queryString ? `?${queryString}` : ''}`);
    };

    const headerContent =
        variant === 'panel' ? (
            <StackLayout orientation="horizontal" align={{ horizontal: 'start', vertical: 'middle' }} className="k-justify-content-between k-mt-6 k-mb-1">
                <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-2 ">
                    <H3>Related canvas boxes</H3>
                    <div className="k-separator k-icp-component-border k-my-0.5" />
                    <Button themeColor="secondary" fillMode="flat" size="small" className="!k-font-normal" onClick={viewBoxesInCanvas}>
                        View in Canvas
                    </Button>
                </StackLayout>
                <Button
                    size="small"
                    fillMode="flat"
                    className="k-icp-svg-icon-button"
                    onClick={e => {
                        setIsCollapsed(prev => !prev);
                    }}
                >
                    {isCollapsed ? <ExpandIcon className="k-icp-icon" /> : <CollapseIcon className="k-icp-icon" />}
                </Button>
            </StackLayout>
        ) : (
            <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-2 k-mb-3">
                <span className="k-text-uppercase k-fs-sm">Related canvas boxes</span>
                <div className="k-flex-1 full-horizontal-separator"></div>
                <Button fillMode="flat" themeColor="secondary" size="small" onClick={viewBoxesInCanvas}>
                    View in Canvas
                </Button>
            </StackLayout>
        );

    return (
        <>
            {headerContent}
            {canvas.boxes ? (
                <Reveal className="k-d-block">
                    {!isCollapsed && (
                        <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-1">
                            {boxes
                                .map(box => ({ box: canvas.boxes?.find(b => b.type === box.type), relatedTask: box.relatedTask }))
                                .filter((b): b is WithNotNullable<typeof b, 'box'> => b.box !== undefined)
                                .map(secondaryBoxDefinition => {
                                    const relatedIdsFilter = showItemsOrRelatedItemsTo
                                        ?.filter(i => i.box !== secondaryBoxDefinition.box.type)
                                        .map(i => i.itemId);
                                    const itemsIdsFilter = showItemsOrRelatedItemsTo?.filter(i => i.box === secondaryBoxDefinition.box.type).map(i => i.itemId);

                                    return (
                                        <CanvasBox
                                            key={secondaryBoxDefinition.box.type}
                                            box={secondaryBoxDefinition.box}
                                            relatableItems={relatableItems}
                                            relatedIdsFilter={relatedIdsFilter && relatedIdsFilter.length ? relatedIdsFilter : undefined}
                                            itemsIdsFilter={itemsIdsFilter && itemsIdsFilter.length ? itemsIdsFilter : undefined}
                                            className={`canvas-box-solo canvas-box-related ${
                                                variant === 'panel' ? ' k-rounded-md canvas-box-related--sm' : ''
                                            }`}
                                            hideMemberActivity={true}
                                            emptyView={
                                                secondaryBoxDefinition.relatedTask
                                                    ? metadata => (
                                                          <TaskRelatedEmptyItemsView
                                                              boxTitle={metadata.title}
                                                              relatedTask={secondaryBoxDefinition.relatedTask!}
                                                              baseUrl="../journey/tasks/"
                                                          />
                                                      )
                                                    : undefined
                                            }
                                            hideFooter={variant === 'panel'}
                                        />
                                    );
                                })}
                        </StackLayout>
                    )}
                </Reveal>
            ) : (
                <LoadingIndicator size="big" className="k-display-block -block-center" />
            )}
        </>
    );
}
