import { Button } from '@progress/kendo-react-buttons';
import { Checkbox, TextAreaProps } from '@progress/kendo-react-inputs';
import { StackLayout } from '@progress/kendo-react-layout';
import { Popover, PopoverPositionEvent } from '@progress/kendo-react-tooltip';
import React, { ReactElement, ReactNode, forwardRef, useEffect, useRef, useState } from 'react';
import { appConfig } from '../../config';
import { useSingleClickButton } from '../../hooks/commonHooks';
import { BoxItem, BoxType, IvaValidationHints } from '../../services/canvasService';
import { combineClassNames, composeFunctions } from '../../services/common';
import { DivButton } from '../common/DivButton';
import { Hints } from '../common/Hints';
import { IVAValidationFunction, IVAValidationPopup } from '../common/IVAValidationPopup';
import { DropDownButtonItem } from '../common/boundDropDownButton';
import { InlineEditor, InlineEditorComponentHandle, InlineEditorComponentProps, InlineEditorHandle, InlineEditorTextArea } from '../common/inlineEditor';
import { defaultValidators } from '../ui/inputs';

export type CanvasBoxItemProps = {
    itemContent?: string;
    boxType?: BoxType;
    relatedItemIds?: number[];
    itemColorCode?: string;
    isEditing?: boolean;
    editContent?: string | undefined;
    onEdit?: () => any;
    onCancel?: (isExplicit: boolean) => any;
    onSave?: (isExplicit: boolean, content: string, relatedItemIds?: number[], colorCode?: string) => any | Promise<any>;
    onDelete?: () => any;
    relatableItems?: BoxItem[];
    filterRelatableItems?: boolean;
    allowedRelatableItemsIds?: number[];
    canEdit?: boolean;
    elementAttributes?: React.HTMLAttributes<HTMLDivElement>;
    forwardElementRef?: (element: HTMLElement | null) => void;
    autoFocus?: boolean;
    onAutoFocus?: () => void;
    showEmptyValidationMessage?: boolean;
    itemShorthand?: string;
    autoHideControls?: boolean;
    hintsValidationFunction?: IVAValidationFunction;
    ivaValidationHints?: IvaValidationHints | undefined;
    hints?: ReactElement;
    autoShowHints?: boolean;
    onToggleHints?: (showHints: boolean) => void;
    allowColoring?: boolean;
    onBeginCommit?: () => void;
    onEndCommit?: () => void;
    requireRelatedItems?: boolean;
    onEditorFocus?: () => void;
};

export default function CanvasBoxItem({
    itemContent,
    relatedItemIds,
    itemColorCode,
    isEditing = false,
    editContent,
    onEdit,
    onCancel,
    onSave,
    onDelete,
    relatableItems,
    filterRelatableItems,
    allowedRelatableItemsIds,
    canEdit = false,
    elementAttributes,
    forwardElementRef,
    autoFocus,
    onAutoFocus,
    showEmptyValidationMessage,
    itemShorthand,
    autoHideControls,
    ivaValidationHints,
    hintsValidationFunction,
    hints,
    autoShowHints,
    onToggleHints,
    allowColoring,
    onBeginCommit,
    onEndCommit,
    requireRelatedItems,
    onEditorFocus
}: CanvasBoxItemProps) {
    const editorRef = useRef<InlineEditorHandle>(null);

    const [showRelatedItemsPicker, setShowRelatedItemsPicker] = useState(false);
    const [showItemColorPicker, setShowItemColorPicker] = useState(false);

    const hasUpdate = useRef(false);
    const [updatedContent, setUpdatedContent] = useState<string | undefined>();
    const [updatedRelatedItemsIds, setUpdatedRelatedItemsIds] = useState<number[]>();
    const [updatedColorCode, setUpdatedColorCode] = useState<string | undefined>();
    const [hasRelatedItemsError, setHasRelatedItemsError] = useState(false);

    const [hideControls, setHideControls] = useState(autoHideControls);
    const [showHints, setShowHints] = useState(false);
    const [deleteInProgress, deleteCallbackCreator] = useSingleClickButton<
        Parameters<NonNullable<typeof onDelete>>,
        ReturnType<NonNullable<typeof onDelete>>
    >();

    const hasHints = !!hints || !!ivaValidationHints;

    useEffect(() => {
        setUpdatedContent(editContent ? editContent : itemContent);
    }, [isEditing, itemContent, editContent]);

    useEffect(() => {
        if (isEditing) {
            hasUpdate.current = false;
        } else {
            setShowRelatedItemsPicker(false);
            setShowItemColorPicker(false);
            setShowHints(false);
        }
    }, [isEditing]);

    useEffect(() => {
        if (isEditing) {
            setUpdatedRelatedItemsIds(relatedItemIds);
        } else {
            setUpdatedRelatedItemsIds(undefined);
        }

        setHasRelatedItemsError(false);
    }, [isEditing, relatedItemIds]);

    useEffect(() => {
        if (isEditing) {
            setUpdatedColorCode(itemColorCode);
        } else {
            setUpdatedColorCode(undefined);
        }
    }, [isEditing, itemColorCode]);

    const [validationErrorMessage, setValidationErrorMessage] = useState('');
    const [showValidationError, setShowValidationError] = useState(false);

    useEffect(() => {
        if (!isEditing) {
            setValidationErrorMessage('');
            setShowValidationError(false);

            return;
        }

        const firstValidationError = defaultValidators.itemTextValidators.map(v => v(updatedContent)).find(v => !!v);
        firstValidationError ? setValidationErrorMessage(firstValidationError) : setValidationErrorMessage('');
        setShowValidationError(!!firstValidationError && (showEmptyValidationMessage || (!!updatedContent && !!updatedContent.length)));
    }, [isEditing, updatedContent, showEmptyValidationMessage]);

    const onEndUpdate = () => {
        onEndCommit?.();
    };

    const updateItem = (explicit: boolean, content: string | undefined, relatedItemsIds: number[] | undefined, colorCode: string | undefined) => {
        if (!content?.length || (requireRelatedItems && !relatedItemsIds?.length)) {
            return;
        }

        onBeginCommit?.();
        const saveResult = onSave?.(explicit, content, relatedItemsIds, colorCode);
        if (saveResult instanceof Promise) return saveResult.finally(onEndUpdate);
        else onEndUpdate();
    };

    const commitUpdate = (explicit: boolean) => {
        setShowHints(false);
        setShowRelatedItemsPicker(false);
        setShowItemColorPicker(false);

        if (!updatedContent || !updatedContent.length) {
            if (explicit) {
                if (editorRef.current?.editor?.element && !editorRef.current.editor.element.contains(document.activeElement))
                    editorRef.current.editor.focus?.();
                return;
            }

            onCancel?.(explicit);

            return;
        }

        let filteredUpdatedRelatedItemsIds =
            updatedRelatedItemsIds && relatableItems
                ? updatedRelatedItemsIds.filter(relatedItemId => relatableItems.some(relatableItem => relatableItem.id === relatedItemId))
                : updatedRelatedItemsIds;
        if (explicit || updatedContent !== itemContent || hasUpdate.current || updatedColorCode !== itemColorCode) {
            if (requireRelatedItems && !filteredUpdatedRelatedItemsIds?.length) {
                if (relatableItems && relatableItems.length === 1) filteredUpdatedRelatedItemsIds = [relatableItems[0].id];
                else {
                    setHasRelatedItemsError(true);
                    setShowRelatedItemsPicker(true);
                    return;
                }
            }

            return updateItem(explicit, updatedContent, filteredUpdatedRelatedItemsIds, updatedColorCode);
        } else {
            onCancel?.(explicit);
        }
    };

    const toggleRelatedItem = (relatedItemId: number) => {
        hasUpdate.current = true;
        let newUpdatedRelatedItemsIds: number[];
        if (!updatedRelatedItemsIds) newUpdatedRelatedItemsIds = [relatedItemId];
        else if (updatedRelatedItemsIds.includes(relatedItemId)) newUpdatedRelatedItemsIds = updatedRelatedItemsIds.filter(r => r !== relatedItemId);
        else newUpdatedRelatedItemsIds = [...updatedRelatedItemsIds, relatedItemId];

        setUpdatedRelatedItemsIds(newUpdatedRelatedItemsIds);
        if (requireRelatedItems) setHasRelatedItemsError(!newUpdatedRelatedItemsIds.length);
    };

    const relateAllowedItems = canEdit
        ? () => {
              if (!allowedRelatableItemsIds?.length) return;

              const relatedItemsIdsIncludingAllowedItems = relatedItemIds ? [...relatedItemIds] : [];
              allowedRelatableItemsIds.forEach(r => {
                  if (!relatedItemsIdsIncludingAllowedItems.includes(r)) relatedItemsIdsIncludingAllowedItems.push(r);
              });

              if (relatedItemsIdsIncludingAllowedItems.length !== relatedItemIds?.length)
                  updateItem(false, itemContent, relatedItemsIdsIncludingAllowedItems, itemColorCode);
          }
        : undefined;

    const unrelateAllowedItems = canEdit
        ? () => {
              if (!allowedRelatableItemsIds?.length) return;

              const relatedItemsIdsExcludingAllowedItems = relatedItemIds?.filter(r => !allowedRelatableItemsIds.includes(r));
              if (relatedItemsIdsExcludingAllowedItems?.length !== relatedItemIds?.length)
                  updateItem(false, itemContent, relatedItemsIdsExcludingAllowedItems, itemColorCode);
          }
        : undefined;

    const itemColors: string[] | undefined = isEditing
        ? updatedColorCode
            ? [updatedColorCode]
            : relatableItems && relatableItems.length && updatedRelatedItemsIds && updatedRelatedItemsIds.length
            ? relatableItems
                  .filter(relatableItem => updatedRelatedItemsIds.includes(relatableItem.id) && relatableItem.colorCode)
                  .map(relatableItem => relatableItem.colorCode as string)
            : undefined
        : itemColorCode
        ? [itemColorCode]
        : relatableItems && relatableItems.length && relatedItemIds && relatedItemIds.length
        ? relatableItems
              .filter(relatableItem => relatedItemIds.includes(relatableItem.id) && relatableItem.colorCode)
              .map(relatableItem => relatableItem.colorCode as string)
        : undefined;

    const relatableItemsInPicker = filterRelatableItems
        ? relatableItems && relatedItemIds
            ? relatableItems.filter(i => relatedItemIds.includes(i.id) || (allowedRelatableItemsIds ? allowedRelatableItemsIds.includes(i.id) : false))
            : undefined
        : relatableItems;
    const showRelatedItemsError = requireRelatedItems && hasRelatedItemsError;

    const isRelatedToAllAllowedItems =
        allowedRelatableItemsIds?.length && relatedItemIds?.length && !allowedRelatableItemsIds.some(r => !relatedItemIds.includes(r));
    const isRelatedToExactlyAllAllowedItems = isRelatedToAllAllowedItems && allowedRelatableItemsIds.length === relatedItemIds.length;

    const deleteActionCallback = onDelete && deleteCallbackCreator(onDelete);
    const editAction: DropDownButtonItem | undefined = onEdit ? { text: 'Edit', danger: false, separated: false, action: onEdit } : undefined;
    const deleteAction: DropDownButtonItem | undefined = onDelete ? { text: 'Delete', danger: true, separated: true, action: deleteActionCallback } : undefined;
    const relateAction: DropDownButtonItem | undefined = relateAllowedItems
        ? { text: 'Relate to this segment', danger: false, separated: false, action: relateAllowedItems }
        : undefined;
    const unRelateAction: DropDownButtonItem | undefined = unrelateAllowedItems
        ? { text: 'Unrelate from this segment', danger: false, separated: false, action: unrelateAllowedItems }
        : undefined;
    const itemActions = (allowedRelatableItemsIds
        ? isRelatedToAllAllowedItems
            ? isRelatedToExactlyAllAllowedItems
                ? [editAction, deleteAction]
                : [editAction, unRelateAction, deleteAction]
            : [relateAction]
        : [editAction, deleteAction]
    ).filter((a): a is DropDownButtonItem => typeof a !== 'undefined');

    const hideRelatedItemsPicker = requireRelatedItems && isRelatedToExactlyAllAllowedItems;

    const onWrapperKeyUp =
        canEdit && onEdit
            ? (e: React.KeyboardEvent<HTMLDivElement>) => {
                  if (e.target !== e.currentTarget || isEditing || e.key !== 'Enter') return;
                  onEdit();
              }
            : undefined;

    const isEditAllowed = canEdit && !deleteInProgress && onEdit && editAction && itemActions.includes(editAction);
    const elementUpdatedAttributes: React.HtmlHTMLAttributes<HTMLDivElement> = { ...elementAttributes };
    elementUpdatedAttributes.className = combineClassNames(elementAttributes?.className, 'canvas-box-item');
    if (isEditAllowed) elementUpdatedAttributes.onKeyUp = composeFunctions(onWrapperKeyUp, elementAttributes?.onKeyUp);

    return (
        <div ref={forwardElementRef} {...elementUpdatedAttributes}>
            <InlineEditor
                ref={editorRef}
                editor={CanvasItemTextEditor}
                editClassName={canEdit && !itemActions.length ? 'inline-editor-edit-has-actions' : undefined}
                editorSettings={{
                    textAreaProps: {
                        rows: 1,
                        autoSize: true,
                        placeholder: itemShorthand ? `Type a ${itemShorthand}...` : `Enter item`,
                        onFocus() {
                            if (!hasRelatedItemsError) setShowRelatedItemsPicker(false);
                            setShowItemColorPicker(false);
                            if (autoHideControls) setHideControls(false);
                            onEditorFocus?.();

                            if (updatedContent && hasHints && autoShowHints && !showHints) setShowHints(true);
                        },
                        onKeyDown(e) {
                            if (e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'ArrowDown' || e.key === 'ArrowUp') e.stopPropagation();
                        }
                    },
                    additionalContent:
                        !hideControls &&
                        (allowColoring ? (
                            <ItemColorPicker
                                expanded={showItemColorPicker}
                                toggleExpanded={() => setShowItemColorPicker(s => !s)}
                                colorCode={updatedColorCode}
                                updateColorCode={c => {
                                    setUpdatedColorCode(c);
                                    setShowItemColorPicker(false);
                                    editorRef.current?.editor?.focus?.();
                                }}
                                flipAnchor={editorRef.current?.editor?.element}
                            />
                        ) : hideRelatedItemsPicker ? (
                            undefined
                        ) : (
                            <RelatedItemsPicker
                                relatableItems={relatableItemsInPicker}
                                expanded={showRelatedItemsPicker}
                                toggleExpanded={() => setShowRelatedItemsPicker(s => !s)}
                                relatedItemsIds={updatedRelatedItemsIds}
                                toggleRelatedItem={toggleRelatedItem}
                                hasError={showRelatedItemsError}
                                flipAnchor={editorRef.current?.editor?.element}
                                enabledRelatableItemsIds={allowedRelatableItemsIds}
                            />
                        ))
                }}
                additionalEditControls={
                    ivaValidationHints && hintsValidationFunction && ivaValidationHints !== undefined ? (
                        <IVAValidationPopup
                            currentTextValue={updatedContent}
                            validationFn={hintsValidationFunction}
                            show={showHints}
                            onToggleHints={() => {
                                onToggleHints?.(!showHints);
                                setShowHints(showHints => !showHints);
                            }}
                            hints={ivaValidationHints}
                        />
                    ) : !ivaValidationHints && hints !== undefined ? (
                        <Hints
                            show={showHints}
                            onToggleHints={() => {
                                onToggleHints?.(!showHints);
                                setShowHints(showHints => !showHints);
                            }}
                            hints={hints}
                        />
                    ) : (
                        undefined
                    )
                }
                autoFocus={autoFocus}
                onAutoFocus={onAutoFocus}
                hideEditControls={hideControls}
                onEdit={isEditAllowed ? onEdit : undefined}
                actions={canEdit ? itemActions : undefined}
                isEditing={isEditing}
                onCancel={onCancel}
                onSave={commitUpdate}
                onEditorChange={e => {
                    setUpdatedContent(e.value);
                    if (e.value && hasHints && autoShowHints && !showHints) setShowHints(true);
                }}
                errorMessage={showValidationError ? validationErrorMessage : undefined}
                value={isEditing ? updatedContent : itemContent}
                viewClassName={deleteInProgress ? 'k-disabled' : undefined}
            />
            {itemColors && !!itemColors.length && (
                <StackLayout className="canvas-box-item-colors k-gap-thin" align={{ horizontal: 'stretch', vertical: 'stretch' }}>
                    {itemColors.map((color, index) => (
                        <div key={index} style={{ backgroundColor: `#${color}` }}></div>
                    ))}
                </StackLayout>
            )}
        </div>
    );
}

function useAutoFlipPopover(defaultAnchor: HTMLElement | null | undefined, flipAnchor?: HTMLElement | null) {
    const [isFlipped, setIsFlipped] = useState(false);

    const onPosition = (e: PopoverPositionEvent) => {
        window.requestAnimationFrame(() => setIsFlipped(e.flipped));
    };

    const onClose = () => {
        setIsFlipped(false);
    };

    return {
        anchor: isFlipped ? flipAnchor : defaultAnchor,
        onPosition,
        onClose
    };
}

function RelatedItemsPicker({
    relatableItems,
    expanded,
    toggleExpanded,
    relatedItemsIds,
    toggleRelatedItem,
    hasError,
    flipAnchor,
    enabledRelatableItemsIds
}: {
    relatableItems?: BoxItem[];
    expanded?: boolean;
    toggleExpanded?: () => void;
    relatedItemsIds?: number[];
    toggleRelatedItem?: (relatedItemId: number) => void;
    flipAnchor?: HTMLElement | null | undefined;
    hasError?: boolean;
    enabledRelatableItemsIds?: number[];
}) {
    const toggleButtonRef = useRef<HTMLDivElement>(null);
    const { anchor: pickerAnchor, onPosition: pickerOnPosition, onClose: pickerOnClose } = useAutoFlipPopover(toggleButtonRef.current, flipAnchor);

    if (!relatableItems || !relatableItems.length) return null;
    const relatedItems: BoxItem[] | undefined =
        relatedItemsIds && relatedItemsIds.length ? relatableItems.filter(i => relatedItemsIds.includes(i.id)) : undefined;

    return (
        <>
            <DivButton
                ref={toggleButtonRef}
                fillMode="flat"
                themeColor={hasError ? 'error' : undefined}
                size="small"
                className={combineClassNames('label-picker-toggle-button k-icp-svg-icon-button', hasError ? 'k-selected' : undefined)}
                onClick={toggleExpanded}
                selected={expanded}
            >
                <StackLayout align={{ horizontal: 'stretch', vertical: 'stretch' }} className="palette-color palette-color-collection">
                    {(relatedItems?.length ? relatedItems.map(r => r.colorCode as string) : appConfig.canvas.orderedPaletteColors)
                        .slice(0, 4)
                        .map((color, index) => (
                            <div key={index} style={{ backgroundColor: `#${color}` }}></div>
                        ))}
                </StackLayout>
            </DivButton>
            <Popover
                anchor={pickerAnchor}
                show={expanded}
                position="right"
                collision={{ horizontal: 'flip', vertical: 'fit' }}
                className="k-icp-no-padding-popover relate-items-popover"
                popoverClass={hasError ? '!k-border-error' : undefined}
                onPosition={pickerOnPosition}
                onClose={pickerOnClose}
                positionMode="fixed"
            >
                <div tabIndex={0} className="-on">
                    <div className={combineClassNames('k-fs-sm k-p-2', hasError ? 'k-text-error' : 'k-icp-subtle-text')}>
                        Relate to one or more Customer Segments
                    </div>
                    <StackLayout
                        orientation="vertical"
                        align={{ horizontal: 'stretch', vertical: 'top' }}
                        className="k-list k-list-md k-px-hair k-pb-hair k-rounded-b"
                    >
                        {relatableItems.map(relatableItem => {
                            const enabled = !enabledRelatableItemsIds || enabledRelatableItemsIds.includes(relatableItem.id);
                            return (
                                <div
                                    key={relatableItem.id}
                                    className={combineClassNames('k-list-item', !enabled ? 'k-disabled' : undefined)}
                                    onClick={toggleRelatedItem && enabled ? () => toggleRelatedItem(relatableItem.id) : undefined}
                                >
                                    <StackLayout align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-2">
                                        <Checkbox checked={relatedItemsIds?.includes(relatableItem.id)} disabled={!enabled} />
                                        <span className="k-flex-shrink-0">
                                            <span
                                                className="palette-color k-display-inline-block -vam"
                                                style={{ backgroundColor: `#${relatableItem.colorCode}` }}
                                            ></span>
                                        </span>
                                        <div>{relatableItem.content}</div>
                                    </StackLayout>
                                </div>
                            );
                        })}
                    </StackLayout>
                </div>
            </Popover>
        </>
    );
}

function ItemColorPicker({
    expanded,
    toggleExpanded,
    colorCode,
    updateColorCode,
    flipAnchor
}: {
    expanded?: boolean;
    toggleExpanded?: () => void;
    colorCode?: string;
    updateColorCode?: (colorCode: string) => void;
    flipAnchor?: HTMLElement | null | undefined;
}) {
    const toggleButtonRef = useRef<HTMLDivElement>(null);
    const { anchor: pickerAnchor, onPosition: pickerOnPosition, onClose: pickerOnClose } = useAutoFlipPopover(toggleButtonRef.current, flipAnchor);

    return (
        <>
            <DivButton
                ref={toggleButtonRef}
                fillMode="flat"
                size="small"
                className="label-picker-toggle-button k-icp-svg-icon-button"
                onClick={toggleExpanded}
                selected={expanded}
            >
                <StackLayout align={{ horizontal: 'stretch', vertical: 'stretch' }} className="palette-color palette-color-collection">
                    {colorCode ? (
                        <div style={{ backgroundColor: `#${colorCode}` }}></div>
                    ) : (
                        appConfig.canvas.orderedPaletteColors.slice(0, 4).map(color => <div key={color} style={{ backgroundColor: `#${color}` }}></div>)
                    )}
                </StackLayout>
            </DivButton>
            <Popover
                anchor={pickerAnchor}
                show={expanded}
                position="right"
                collision={{ horizontal: 'flip', vertical: 'fit' }}
                onPosition={pickerOnPosition}
                onClose={pickerOnClose}
                className="k-icp-no-padding-popover"
                positionMode="fixed"
            >
                <div tabIndex={0} className="-on">
                    <div className="k-fs-sm k-icp-subtle-text k-p-2">Assign color</div>
                    <div className="k-px-1 k-pb-1 k-d-grid k-grid-cols-6">
                        {appConfig.canvas.palette.map(paletteItem => (
                            <Button
                                key={paletteItem.color}
                                fillMode="flat"
                                size="small"
                                className="k-icp-svg-icon-button"
                                onClick={updateColorCode ? () => updateColorCode(paletteItem.color) : undefined}
                            >
                                <div
                                    className={combineClassNames('palette-color', paletteItem.color === colorCode ? 'palette-color-marked' : undefined)}
                                    style={{ backgroundColor: `#${paletteItem.color}` }}
                                ></div>
                            </Button>
                        ))}
                    </div>
                </div>
            </Popover>
        </>
    );
}

const CanvasItemTextEditor = forwardRef<
    InlineEditorComponentHandle,
    InlineEditorComponentProps<string, { textAreaProps: TextAreaProps; additionalContent?: ReactNode }>
>(function(props, ref) {
    return (
        <div className="k-pos-relative">
            <InlineEditorTextArea ref={ref} {...props} settings={props.settings?.textAreaProps} />
            {props.settings?.additionalContent}
        </div>
    );
});
