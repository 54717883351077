import { BoxType, RelatedTaskData } from './canvasService';
import { ErrorWithOperationDisplayName } from './common';
import { HttpException, HttpServiceBase, RequestMethod } from './httpServiceBase';
import { HypothesisType } from './hypothesesService';
// import { InterviewScriptParameterValue } from './interviewScriptsService';
import { ResearchType } from './researchService';
import { ReducedUser } from './usersService';

export enum JourneyNodeType {
    Task = 'Task',
    Start = 'Start',
    Milestone = 'Milestone'
}

export interface JourneyNodeVariation {
    tag: string;
    locked: boolean;
    status?: JourneyTaskStatus;
    customerSegmentId: number;
}

export interface JourneyNodeDetail {
    tag: string;
    sequence: string;
    locked: boolean;
    status?: JourneyTaskStatus;
    variations?: JourneyNodeVariation[];
}

export interface JourneyNode {
    type: JourneyNodeType;
    title: string;
    name: string;
    detail?: JourneyNodeDetail;
}

export interface JourneyPhase {
    title: string;
    nodes: JourneyNode[];
}

export interface Journey {
    title: string;
    phases: JourneyPhase[];
}

export interface JourneyTaskMarker {
    currentPageTag?: string | null;
    currentTaskTag: string;
    currentSequenceTag: string;
    currentTaskVariationTag?: string;
    user: ReducedUser;
}

export enum JourneyTaskStatus {
    New = 'New',
    WorkInProgress = 'WorkInProgress',
    Ready = 'Ready'
}

export enum PlatformArea {
    None = 'None',
    Canvas = 'Canvas',
    Hypotheses = 'Hypotheses',
    Research = 'Research'
}

export interface CanvasTaskDetail {
    type: PlatformArea.Canvas;
    boxDependencies: BoxType[];
}

export interface HypothesesTaskDetail {
    type: PlatformArea.Hypotheses;
    hypothesesType: HypothesisType[];
}

export interface ResearchTaskDetail {
    type: PlatformArea.Research;
    researchType: ResearchType;
}

export type AreaDetail = CanvasTaskDetail | HypothesesTaskDetail | ResearchTaskDetail;

export interface JourneyTask {
    sequence: string;
    tag: string;
    variation: string;
    platformArea: PlatformArea;
    areaDetail: AreaDetail;
    index: number;
    status: JourneyTaskStatus;
    title: string;
    duration: string;
    goal: string;
    graphic?: string;
    body: JourneyTaskBody;
}

export interface JourneyTaskRule {
    name: string;
    params: any[];
}

export interface JourneyTaskRelatedItem {
    itemId: number;
    box: BoxType;
}

export type JourneyTaskEditor =
    | CanvasBoxEditor
    | CustomerProblemHypothesesEditor
    | JobToBeDoneEditor
    | InterviewScriptEditor
    | ResearchDateRangeEditor
    | ResearchSchedulesEditor
    | ResearchContactsEditor
    | ResearchMeetingInvitesEditor
    | ResearchMeetingsExecutionEditor
    | HypothesesLikelihoodEditor;

export type CanvasBoxEditor = {
    type: 'canvas-box';
    params: CanvasBoxEditorParams;
};

export type CanvasBoxEditorParams =
    | CanvasBoxEditorParamsWithSecondaryBoxes
    | CanvasBoxEditorParamsWithGraphic
    | CanvasBoxEditorParamsWithGuidance
    | CanvasBoxEditorParamsWithSecondaryBoxesAndGuidance;

type CanvasBoxEditorParamsBase = {
    type: string;
    boxType: BoxType;
    hintsRef?: string;
    relatedItems?: JourneyTaskRelatedItem[];
};

export type SecondaryBox = { type: BoxType; relatedTask?: RelatedTaskData };

export type CanvasBoxEditorParamsWithSecondaryBoxes = CanvasBoxEditorParamsBase & {
    type: 'editor-with-boxes';
    secondaryBoxes: SecondaryBox[];
};

export type CanvasBoxEditorParamsWithGraphic = CanvasBoxEditorParamsBase & {
    type: 'editor-with-graphic';
    graphic: string;
};

export type CanvasBoxEditorParamsWithGuidance = CanvasBoxEditorParamsBase & {
    type: 'editor-with-guidance';
    guidanceRef: string;
};

export type CanvasBoxEditorParamsWithSecondaryBoxesAndGuidance = CanvasBoxEditorParamsBase & {
    type: 'editor-with-boxes-and-guidance';
    secondaryBoxes: SecondaryBox[];
    guidanceRef: string;
};

export type CustomerProblemHypothesesEditor = {
    type: 'problem-hypotheses';
    params: CustomerProblemHypothesesEditorParams;
};

export type CustomerProblemHypothesesEditorParams = {
    customerSegmentId: number;
    customerProblemId: number;
    researchId: number;
};

export type JobToBeDoneEditor = {
    type: 'job-to-be-done';
    params: JobToBeDoneEditorParams;
};

export type JobToBeDoneEditorParams = {
    customerSegmentId: number;
    secondaryBoxes: SecondaryBox[];
    researchId: number;
    hintsRef: string;
};

export type InterviewScriptEditor = {
    type: 'interview-script';
    params: InterviewScriptEditorParams;
};

export type InterviewScriptEditorParams = {
    researchId: number;
    questionHintsRef: string;
};

export type ResearchDateRangeEditor = {
    type: 'research-start-end-date';
    params: ResearchEditorParams;
};

export type ResearchEditorParams = {
    researchId: number;
};

export type ResearchSchedulesEditor = {
    type: 'research-meeting-proposals';
    params: ResearchEditorParams;
};

export type ResearchContactsEditor = {
    type: 'research-contacts';
    params: ResearchContactsEditorParams;
};

export type ResearchContactsEditorParams = ResearchEditorParams & {
    customerSegmentId: number;
};

export type ResearchMeetingInvitesEditor = {
    type: 'research-meeting-invites';
    params: ResearchEditorParams;
};

export type ResearchMeetingsExecutionEditor = {
    type: 'research-interviews';
    params: ResearchContactsEditorParams;
};

export type HypothesesLikelihoodEditor = {
    type: 'hypotheses-likelihood';
    params: HypothesesLikelihoodEditorParams;
};

export type HypothesesLikelihoodEditorParams = {
    customerSegmentId: number;
    customerProblemId: number;
    researchId: number;
};

export interface JourneyTaskPage {
    title: string;
    subtitle: string;
    tag: string;
    relatedItems?: JourneyTaskRelatedItem[];
    readingOptional?: boolean;
    guidanceRef?: string;
    editor?: JourneyTaskEditor;
    rules: JourneyTaskRule[];
}

export enum JourneyTaskNavigationTarget {
    GoToCanvas = 'GoToCanvas',
    GoToJourney = 'GoToJourney'
}

export enum JourneyTaskNavigationHighlight {
    FocusTitle = 'FocusTitle',
    FocusCanvas = 'FocusCanvas',
    FocusHistory = 'FocusHistory',
    FocusSegments = 'FocusSegments',
    FocusProblem = 'FocusProblem',
    FocusUVP = 'FocusUVP',
    FocusSolution = 'FocusSolution',
    FocusAdvantage = 'FocusAdvantage',
    FocusRevenue = 'FocusRevenue',
    FocusCost = 'FocusCost',
    FocusMetrics = 'FocusMetrics',
    FocusChannel = 'FocusChannel',
    TaskUnlockedNotice = 'TaskUnlockedNotice'
}

export interface JourneyTaskNavigationInstruction {
    target: JourneyTaskNavigationTarget;
    highlights?: JourneyTaskNavigationHighlight[];
}

export interface JourneyTaskCompletionButton {
    text: string;
    navigationInstruction: JourneyTaskNavigationInstruction;
}

export interface JourneyTaskCompletion {
    completionPromptTitle: string;
    completionPromptContent: string;
    buttons: JourneyTaskCompletionButton[];
}

type JourneyTaskGuidanceBase = {
    id: string;
    type: string;
    items: string[];
};

export type JourneyTaskGuidanceHints = JourneyTaskGuidanceBase & {
    type: 'guidance-hints';
    title: string;
};

export type JourneyTaskGuidanceWithExamples = JourneyTaskGuidanceBase & {
    type: 'guidance-examples';
    examples: string[];
    examplesPageSize: number;
};

export type JourneyTaskGuidanceWithGraphic = JourneyTaskGuidanceBase & {
    type: 'guidance-graphic';
    graphic: string;
};

export type JourneyTaskGuidanceWithGraphicPopup = JourneyTaskGuidanceBase & {
    type: 'guidance-graphic-popup';
    graphic: string;
    popupGraphic: string;
    popupTitle: string;
    backgroundGraphic: string;
    text: string;
    buttonText: string;
};

export type JourneyTaskGuidance =
    | JourneyTaskGuidanceHints
    | JourneyTaskGuidanceWithExamples
    | JourneyTaskGuidanceWithGraphic
    | JourneyTaskGuidanceWithGraphicPopup;

export interface JourneyTaskBody {
    pages: JourneyTaskPage[];
    rules: JourneyTaskRule[];
    completion: JourneyTaskCompletion;
    guidance?: JourneyTaskGuidance[];
}

export interface JourneyTaskValidationResult {
    isValid: boolean;
    message: string;
}

export interface JourneyTaskValidationSummary {
    isValid: boolean;
    results: Record<string, JourneyTaskValidationResult[]>;
}

export type SkippingTasksWarning = {
    warningType: 'TaskSkip';
    oldTaskNumber: number;
    oldTaskName: string;
    newTaskNumber: number;
    newTaskName: string;
};

export type ChangingSegmentsWarning = {
    warningType: 'SegmentChange';
    oldSegmentId: number;
    newSegmentId: number;
};

export type MarkerTransitionWarning = SkippingTasksWarning | ChangingSegmentsWarning;

class JourneyService extends HttpServiceBase {
    constructor() {
        super('/api/journey');
    }

    @ErrorWithOperationDisplayName('Get startup journey')
    get(ideaId: string) {
        return this.performRequest<Journey>({
            path: `/${ideaId}`
        });
    }

    @ErrorWithOperationDisplayName("Get everyone's journey position")
    getTaskMarkers(ideaId: string) {
        return this.performRequest<JourneyTaskMarker[]>({
            path: `/${ideaId}/taskMarkers`
        });
    }

    @ErrorWithOperationDisplayName('Get task')
    getTask(ideaId: string, sequenceTag: string, taskTag: string, variationTag?: string) {
        return this.performRequest<JourneyTask>({
            path: `/${ideaId}/tasks/${sequenceTag}/${taskTag}${this.getVariationSegment(variationTag)}`,
            method: RequestMethod.GET
        });
    }

    @ErrorWithOperationDisplayName("Get user's journey position")
    getTaskUserMarker(ideaId: string, userId: string) {
        return this.performRequest<JourneyTaskMarker>({
            path: `/${ideaId}/taskMarkers/${userId}`
        });
    }

    @ErrorWithOperationDisplayName("Get current user's journey position")
    getCurrentUserTaskMarker(ideaId: string) {
        return this.performRequest<JourneyTaskMarker>({
            path: `/${ideaId}/taskMarkers/mine`
        }).catch(e => {
            if (e instanceof HttpException && e.status === 404) {
                return null;
            }

            return Promise.reject(e);
        });
    }

    @ErrorWithOperationDisplayName("Validate change of current user's journey position")
    async validateMarkerTransition(
        ideaId: string,
        sequenceTag: string,
        taskTag: string,
        variationTag: string | undefined,
        pageTag: string | undefined
    ): Promise<MarkerTransitionWarning | undefined> {
        try {
            await this.performRequestWithoutParsingResponse({
                path: `/${ideaId}/taskMarkers/mine/validateTransition`,
                method: RequestMethod.POST,
                body: {
                    sequenceTag,
                    taskTag,
                    variationTag,
                    pageTag
                }
            });

            return undefined;
        } catch (error) {
            if (error instanceof HttpException && error.status === 409) {
                const skippedTasksErrorData = error.parseResponse<{
                    details: MarkerTransitionWarning;
                }>();

                return skippedTasksErrorData.details;
            }

            throw error;
        }
    }

    @ErrorWithOperationDisplayName("Set current user's journey position")
    setCurrentUserTaskMarker(ideaId: string, sequenceTag: string, taskTag: string, variationTag: string | undefined, pageTag: string | undefined) {
        return this.performRequest<JourneyTaskMarker>({
            path: `/${ideaId}/taskMarkers/mine`,
            method: RequestMethod.PUT,
            body: {
                sequenceTag: sequenceTag,
                taskTag: taskTag,
                variationTag: variationTag,
                pageTag: pageTag
            }
        });
    }

    @ErrorWithOperationDisplayName('Update task status')
    updateTaskStatus(
        ideaId: string,
        sequenceTag: string,
        taskTag: string,
        variationTag: string | undefined,
        newStatus: JourneyTaskStatus
    ): Promise<JourneyTaskStatus> {
        return this.performRequest<{ status: JourneyTaskStatus }>({
            path: `/${ideaId}/tasks/${sequenceTag}/${taskTag}${this.getVariationSegment(variationTag)}/status`,
            method: RequestMethod.PUT,
            body: {
                status: newStatus
            }
        }).then(r => r.status);
    }

    @ErrorWithOperationDisplayName('Validate task')
    validateTask(ideaId: string, sequenceTag: string, taskTag: string, variationTag?: string) {
        return this.performRequest<JourneyTaskValidationSummary>({
            path: `/${ideaId}/tasks/${sequenceTag}/${taskTag}${this.getVariationSegment(variationTag)}/validation`
        });
    }

    @ErrorWithOperationDisplayName('Validate task step')
    validateTaskPage(ideaId: string, sequenceTag: string, taskTag: string, variationTag: string | undefined, pageTag: string) {
        return this.performRequest<JourneyTaskValidationSummary>({
            path: `/${ideaId}/tasks/${sequenceTag}/${taskTag}${this.getVariationSegment(variationTag)}/pages/${pageTag}/validation`
        });
    }

    private getVariationSegment(variationTag?: string) {
        if (variationTag) return `/${variationTag}`;

        return '';
    }
}

export const journeyService = new JourneyService();
