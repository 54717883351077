import { Button } from '@progress/kendo-react-buttons';
import { StackLayout } from '@progress/kendo-react-layout';
import { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import StartupCard from '../../components/startup/startupCard';
import LoadingIndicator from '../../components/ui/loadingIndicator';
import { H1 } from '../../components/ui/typography';
import { useConfirmDialog } from '../../hooks/dialogHooks';
import { useRealTimeUpdatesFocus } from '../../hooks/realTimeUpdatesHooks';
import { ReactComponent as PlusIcon } from '../../icons/plus.svg';
import blankStartupsImage from '../../images/startups-blank.svg';
import { googleTagManager } from '../../scripts/googleTagManager';
import { AcceptedInviteEventData, appEventHub } from '../../services/appEvents';
import { dateTimeService } from '../../services/dateTimeService';
import { Idea, Invite, ideasService } from '../../services/ideasService';
import { ConnectionFocus, RealTimeUpdateIdeaEventData, realTimeUpdatesEventHub } from '../../services/realTimeUpdatesService';
import { ReducedUserViewModel, UserMembershipEntry, UserRole, generateInitials, getPreferredColorIndex } from '../../services/usersService';
import { useAppSelector } from '../../state/hooks';

export interface UserMembershipViewModel extends UserMembershipEntry {
    user: ReducedUserViewModel;
}
export interface IdeaViewModel extends Idea {
    currentUserRole?: UserRole;
    memberships?: UserMembershipViewModel[];
    editedAgo?: string;
}

const castIdeaToIdeaViewModel = (idea: Idea, currentUserId: string | undefined): IdeaViewModel => {
    const ideaViewModel = idea as IdeaViewModel;
    ideaViewModel.currentUserRole = idea.memberships?.find(m => m.user.userId === currentUserId)?.role;
    ideaViewModel.memberships?.forEach(m => {
        m.user.initials = generateInitials(m.user, 2);
        m.user.colorIndex = getPreferredColorIndex(m.user);
    });

    if (idea.stats?.lastActivity) {
        ideaViewModel.editedAgo = dateTimeService.stringifyDuration(new Date(), idea.stats.lastActivity);
    }

    return ideaViewModel;
};

export default function StartupsPage() {
    const [ideas, setIdeas] = useState<IdeaViewModel[]>([]);
    const [loading, setLoading] = useState(true);
    const currentUserId = useAppSelector(s => s.user?.userId);
    const { show: showConfirmDialog, element: confirmDialog } = useConfirmDialog();
    useRealTimeUpdatesFocus(ConnectionFocus.AllIdeas);
    const navigate = useNavigate();

    const fetchAvailableIdeas = useCallback(async () => {
        const availableIdeasResponse = await ideasService.getAvailable(true, true, true);
        setIdeas(availableIdeasResponse.map(i => castIdeaToIdeaViewModel(i, currentUserId)));
    }, [currentUserId]);

    const loadAvailableIdeas = useCallback(async () => {
        setLoading(true);
        try {
            await fetchAvailableIdeas();
        } finally {
            setLoading(false);
        }
    }, [fetchAvailableIdeas]);

    useEffect(() => {
        loadAvailableIdeas();
    }, [loadAvailableIdeas]);

    const onCreateStartupClick = async () => {
        const createdStartup = await ideasService.createDefault();
        googleTagManager.reportStartupCreatedEvent();
        navigate(`/startups/${createdStartup.uniqueId}/get-started`);
    };

    const deleteIdea = async (ideaId: string) => {
        setIdeas(ideas.filter(i => i.uniqueId !== ideaId));
        await ideasService.delete(ideaId);
    };

    const deleteIdeaWithConfirmation = (ideaId: string) => {
        const ideaToDelete = ideas.find(i => i.uniqueId === ideaId);
        if (!ideaToDelete) return;

        showConfirmDialog({
            title: 'Delete startup',
            content: (
                <>
                    Are you sure you want to delete the <strong>“{ideaToDelete.title}”</strong> startup?
                </>
            ),
            confirmCheckboxText: 'I acknowledge that this cannot be undone',
            confirmButtonText: 'Delete startup',
            callback: () => deleteIdea(ideaId)
        });
    };

    const leaveIdea = async (ideaId: string) => {
        setIdeas(ideas.filter(i => i.uniqueId !== ideaId));
        await ideasService.leave(ideaId);
    };

    const leaveIdeaWithConfirmation = (ideaId: string) => {
        const ideaToLeave = ideas.find(i => i.uniqueId === ideaId);
        if (!ideaToLeave) return;

        showConfirmDialog({
            title: 'Leave startup',
            content: (
                <>
                    Are you sure you want to leave <strong>“{ideaToLeave.title}”</strong> startup?
                </>
            ),
            confirmButtonText: 'Leave startup',
            callback: () => leaveIdea(ideaId)
        });
    };

    const onStartupClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        const clickedElement = e.target as HTMLElement;
        if (!clickedElement) return;
        // Prevent default if:
        // 1. The clicked element is outside of the link in the DOM thee but is inside in the react virtual dom tree or
        // 2. The clicked element is inside a startup-card-actions-area
        if (!e.currentTarget.contains(clickedElement) || clickedElement.closest('.startup-card-actions-area')) e.preventDefault();
    };

    const onInviteCreated = (ideaId: string, invite: Invite) => {
        setIdeas(ideas =>
            ideas.map(i => {
                if (i.uniqueId !== ideaId) return i;

                return { ...i, invites: [...(i.invites || []), invite] };
            })
        );
    };

    const refreshIdea = useCallback(
        async (e: RealTimeUpdateIdeaEventData | AcceptedInviteEventData) => {
            const freshIdea = await ideasService.get(e.ideaId, true, true, true);
            if (!freshIdea) return;

            const freshIdeaViewModel = castIdeaToIdeaViewModel(freshIdea, currentUserId);

            setIdeas(ideas => [freshIdeaViewModel, ...ideas.filter(i => i.uniqueId !== freshIdeaViewModel.uniqueId)]);
        },
        [currentUserId]
    );

    useEffect(() => {
        const removeIdea = (e: RealTimeUpdateIdeaEventData) => {
            setIdeas(ideas => ideas.filter(i => i.uniqueId !== e.ideaId));
        };

        appEventHub.addEventListener('invitation', 'accepted', refreshIdea);

        realTimeUpdatesEventHub.addEventListener('membership', 'new', refreshIdea);
        realTimeUpdatesEventHub.addEventListener('membership', 'change', refreshIdea);
        realTimeUpdatesEventHub.addEventListener('membership', 'revoke', removeIdea);

        realTimeUpdatesEventHub.addEventListener('idea', 'created', refreshIdea);
        realTimeUpdatesEventHub.addEventListener('idea', 'updated', refreshIdea);
        realTimeUpdatesEventHub.addEventListener('idea', 'restored', refreshIdea);
        realTimeUpdatesEventHub.addEventListener('idea', 'deleted', removeIdea);

        realTimeUpdatesEventHub.addEventListener('connection', 'reconnected', fetchAvailableIdeas);

        return () => {
            appEventHub.removeEventListener('invitation', 'accepted', refreshIdea);

            realTimeUpdatesEventHub.removeEventListener('membership', 'new', refreshIdea);
            realTimeUpdatesEventHub.removeEventListener('membership', 'change', refreshIdea);
            realTimeUpdatesEventHub.removeEventListener('membership', 'revoke', removeIdea);

            realTimeUpdatesEventHub.removeEventListener('idea', 'created', refreshIdea);
            realTimeUpdatesEventHub.removeEventListener('idea', 'updated', refreshIdea);
            realTimeUpdatesEventHub.removeEventListener('idea', 'restored', refreshIdea);
            realTimeUpdatesEventHub.removeEventListener('idea', 'deleted', removeIdea);

            realTimeUpdatesEventHub.removeEventListener('connection', 'reconnected', fetchAvailableIdeas);
        };
    }, [refreshIdea, fetchAvailableIdeas]);

    return (
        <div className="main-column page-content-section">
            <StackLayout className="k-gap-8 heading-row" align={{ horizontal: 'start', vertical: 'middle' }}>
                <H1>My startups</H1>
                {!loading && !!ideas.length && (
                    <>
                        <div className="vertical-separator"></div>
                        <Button onClick={onCreateStartupClick} themeColor="primary" size="large">
                            <StackLayout className="k-gap-1">
                                <PlusIcon className="k-icp-icon" />
                                New startup
                            </StackLayout>
                        </Button>
                    </>
                )}
            </StackLayout>
            <div className={!loading && ideas.length ? '' : 'page-content-middle k-text-center'}>
                {loading ? (
                    <LoadingIndicator size="big" />
                ) : ideas.length ? (
                    <div className="cards-relaxed-grid">
                        {ideas.map(idea => {
                            const redirectUrl = ideasService.getStartupRedirectUrl(idea);
                            return (
                                <Link key={idea.uniqueId} className="k-link" to={redirectUrl} onClick={onStartupClick}>
                                    <StartupCard
                                        idea={idea}
                                        onView={() => navigate(redirectUrl)}
                                        onSettings={ideasService.isIntroChatPending(idea) ? undefined : () => navigate(`startups/${idea.uniqueId}/settings`)}
                                        onDelete={() => deleteIdeaWithConfirmation(idea.uniqueId)}
                                        onLeave={() => leaveIdeaWithConfirmation(idea.uniqueId)}
                                        onInvited={i => onInviteCreated(idea.uniqueId, i)}
                                    />
                                </Link>
                            );
                        })}
                    </div>
                ) : (
                    <StackLayout orientation="vertical" align={{ horizontal: 'center' }}>
                        <img src={blankStartupsImage} alt="No startups" width={462} height={266} className="-mw100 k-mb-13 k-display-inline-block" />
                        <Button onClick={onCreateStartupClick} themeColor="primary" size="large" className="!k-mb-7">
                            <StackLayout className="k-gap-1">
                                <PlusIcon className="k-icp-icon" />
                                Create a startup
                            </StackLayout>
                        </Button>
                        <div className="page-content-middle page-content-middle--small">
                            A startup is where work happens in Icanpreneur. Create startups to explore, validate, and evolve each of your business ideas. Follow
                            the Journey to stay on track and organized.
                        </div>
                    </StackLayout>
                )}
            </div>
            {confirmDialog}
        </div>
    );
}
