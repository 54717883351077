import { StackLayout } from '@progress/kendo-react-layout';
import { Popover } from '@progress/kendo-react-tooltip';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAsRef, useFittingPopoverCallout } from '../../hooks/commonHooks';
import { ReactComponent as CheckCircleClosedIcon } from '../../icons/check-circle-closed.svg';
import { ReactComponent as JourneyMapIcon } from '../../icons/map.svg';
import { ReactComponent as XCircleIcon } from '../../icons/x-circle.svg';
import { IvaValidationHints } from '../../services/canvasService';
import { debounce } from '../../services/common';
import { domService } from '../../services/domService';
import { AIAvatar } from '../ai/aiAvatar';
import { PopupPropsNestedContextProvider } from '../common/popupPropsNestedContextProvider';

export type IVAValidationFunction = (text: string, signal?: AbortSignal) => Promise<boolean[]>;

interface IVAValidationPopupProps {
    hints: IvaValidationHints;
    validationFn: IVAValidationFunction;
    currentTextValue?: string;
    show?: boolean;
    onToggleHints?: () => void;
}

const DEBOUNCE_TIME_MS = 300;

export function IVAValidationPopup({ currentTextValue, show, validationFn, onToggleHints, hints }: IVAValidationPopupProps) {
    const hintsButtonRef = useRef<HTMLDivElement>(null);
    const hintsWrapperRef = useRef<HTMLDivElement>(null);
    const [popoverId, onPopoverPosition] = useFittingPopoverCallout('horizontal', hintsButtonRef.current);

    const lastValidatedText = useRef<string | undefined>(undefined);
    const trimmedValue = useMemo(() => currentTextValue?.trim(), [currentTextValue]);
    const currentTextRef = useAsRef(trimmedValue);
    const [hintValidity, setHintValidity] = useState<boolean[] | undefined>(undefined);

    const isValidationPending = !!trimmedValue && hintValidity === undefined;
    const validationFnRef = useAsRef(validationFn);
    const abortControllerRef = useRef<AbortController>();

    const debouncedValidateRef = useRef(
        debounce(async (text: string) => {
            if (abortControllerRef.current) {
                abortControllerRef.current.abort();
            }
            abortControllerRef.current = new AbortController();

            try {
                const validity = await validationFnRef.current(text, abortControllerRef.current.signal);

                if (currentTextRef.current !== text) {
                    setHintValidity(undefined);
                    lastValidatedText.current = undefined;
                    return;
                }
                setHintValidity(validity);
                lastValidatedText.current = text;
            } catch (error) {
                if (error instanceof DOMException && error.name === 'AbortError') return;
                throw error;
            }
        }, DEBOUNCE_TIME_MS)
    );

    // Fixes issue with wrong scaling of hint validation items on IPhones
    // Force re-render of the hints wrapper fixes the issue
    // Work item: https://trello.com/c/EhKiE3VY/562-on-iphones-iva-validation-popup-hints-have-wrong-sizes
    useEffect(() => {
        if (show) {
            hintsWrapperRef.current!.style.display = 'none';
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            hintsWrapperRef.current!.offsetHeight;
            hintsWrapperRef.current!.style.display = '';
        }
    }, [show]);

    useEffect(() => {
        if (!show) return;

        function ensureHintsInViewport() {
            if (!hintsWrapperRef.current) return;
            const hintsPopupWrapper = hintsWrapperRef.current.closest<HTMLElement>('.k-animation-container');
            if (!hintsPopupWrapper) return;
            domService.scrollIntoViewIfNeeded(hintsPopupWrapper, false, 'auto');
        }

        ensureHintsInViewport();
    }, [show]);

    useEffect(() => {
        if (!trimmedValue) {
            setHintValidity(undefined);
            lastValidatedText.current = undefined;
            return;
        }

        //Clear validation if text changed
        if (lastValidatedText.current !== trimmedValue) {
            setHintValidity(undefined);
            lastValidatedText.current = undefined;
        }

        debouncedValidateRef.current(trimmedValue);

        return () => {
            if (abortControllerRef.current) {
                abortControllerRef.current.abort();
            }
        };
    }, [trimmedValue, debouncedValidateRef]);

    return (
        <>
            <AIAvatar
                ref={hintsButtonRef}
                clickable
                animate={isValidationPending}
                size={24}
                iconSize={16}
                onClick={onToggleHints}
                className="k-cursor-pointer"
            />
            <PopupPropsNestedContextProvider
                value={p => ({
                    ...p,
                    anchorAlign: { horizontal: 'left', vertical: 'bottom' },
                    popupAlign: { horizontal: 'left', vertical: 'top' }
                })}
            >
                <Popover
                    id={popoverId}
                    anchor={hintsButtonRef.current}
                    position="bottom"
                    popoverClass={`k-icp-tooltip-popover-light hints-tooltip-popover`}
                    show={show}
                    margin={{ vertical: 8, horizontal: 0 }}
                    onPosition={onPopoverPosition}
                >
                    <div ref={hintsWrapperRef} onMouseDown={e => e.preventDefault()}>
                        <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="k-gap-2 k-fs-sm k-p-3">
                            <span>{hints.title}</span>
                            {hints.items.map((hintItem, index) => (
                                <StackLayout align={{ horizontal: 'start', vertical: 'top' }} key={hintItem} className="k-gap-2">
                                    {hintValidity === undefined ? (
                                        <CheckCircleClosedIcon
                                            className={`k-icp-icon k-shrink-0 k-text-disabled ${isValidationPending ? 'opacity-subtle-pulse' : ''}`}
                                        />
                                    ) : hintValidity[index] ? (
                                        <CheckCircleClosedIcon className="k-icp-icon k-shrink-0 k-text-success" />
                                    ) : (
                                        <XCircleIcon className="k-icp-icon k-shrink-0 k-text-error" />
                                    )}
                                    <div dangerouslySetInnerHTML={{ __html: hintItem }} />
                                </StackLayout>
                            ))}
                        </StackLayout>
                        {hints.relatedTaskData && (
                            <div className="k-text-secondary">
                                <div className="k-separator k-icp-component-border" />
                                <Link
                                    to={`../journey/tasks/${hints.relatedTaskData.sequence}/${hints.relatedTaskData.tag}`}
                                    className="k-button k-button-sm k-button-link k-m-2"
                                >
                                    <JourneyMapIcon className="k-icp-icon k-shrink-0" />
                                    Go to Journey Task {hints.relatedTaskData.index}
                                </Link>
                            </div>
                        )}
                    </div>
                </Popover>
            </PopupPropsNestedContextProvider>
        </>
    );
}
