import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { ideasService } from '../../services/ideasService';

export default function GenerateIdeaPage() {
    const navigate = useNavigate();
    const isGenerating = useRef(false);

    useEffect(() => {
        const generateAndRedirect = async () => {
            if (isGenerating.current) return;
            isGenerating.current = true;

            try {
                const existingIdeas = await ideasService.getAvailable();
                if (existingIdeas.length > 0) {
                    navigate('/');
                    return;
                }

                const idea = await ideasService.createDefault();
                const redirectUrl = ideasService.getStartupRedirectUrl(idea);
                navigate(redirectUrl);
            } finally {
                isGenerating.current = false;
            }
        };

        generateAndRedirect();
    }, [navigate]);

    return null;
}
