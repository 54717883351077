import { Button } from '@progress/kendo-react-buttons';
import { Skeleton } from '@progress/kendo-react-indicators';
import { StackLayout } from '@progress/kendo-react-layout';
import { useEffect, useState } from 'react';
import { BoxType, canvasService } from '../../../../services/canvasService';
import { AIAvatar } from '../../../ai/aiAvatar';
import { Icon } from '../../../ui/icon';
import { H3 } from '../../../ui/typography';

interface IVASuggestionsPanelProps {
    disabled?: boolean;
    onPickSuggestion: (suggestion: string) => void;
    ideaId: string;
    boxType: BoxType;
    shouldLoadSuggestions: boolean;
}

export function IVASuggestionsPanel({ ideaId, boxType, disabled, shouldLoadSuggestions, onPickSuggestion }: IVASuggestionsPanelProps) {
    const [currentSuggestionIdx, setCurrentSuggestionIdx] = useState(1);
    const [suggestions, setSuggestions] = useState<string[] | undefined>(undefined);

    useEffect(() => {
        if (!shouldLoadSuggestions) return;

        async function loadSuggestions() {
            const result = await canvasService.getSuggestions(ideaId, boxType);
            setSuggestions(result.suggestions);
        }
        loadSuggestions();
    }, [shouldLoadSuggestions, ideaId, boxType]);

    return (
        <div className="k-mt-4">
            <StackLayout align={{ horizontal: 'start', vertical: 'stretch' }} className="k-justify-content-between">
                <H3>Suggestions</H3>
                {/* TODO for now we won't implement generate more. Leaving the code when functionality is needed
        <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-2">
            <div className="k-separator k-icp-component-border k-my-0.5" />
            <Button themeColor="secondary" fillMode="flat" size="small" className="!k-font-normal" onClick={() => props.onPickSuggestion('')}>
                Generate more
            </Button>
        </StackLayout> */}
                {suggestions && suggestions.length > 1 && (
                    <SuggestionPager
                        currentPage={currentSuggestionIdx}
                        maxPages={suggestions.length}
                        onPageChange={(_, currentIdx) => {
                            setCurrentSuggestionIdx(currentIdx);
                        }}
                    />
                )}
            </StackLayout>

            <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} className="k-mt-1 k-rounded k-icp-panel-base k-px-2 k-py-1 k-gap-2 ">
                {suggestions && suggestions.length > 0 ? (
                    <>
                        <div className="k-fs-sm k-flex-1">{suggestions[currentSuggestionIdx - 1]}</div>
                        <Button
                            disabled={disabled}
                            themeColor="secondary"
                            fillMode="flat"
                            size="small"
                            className="!k-min-w-0 k-shrink-0"
                            onClick={() => onPickSuggestion(suggestions[currentSuggestionIdx - 1])}
                        >
                            Use it
                        </Button>
                    </>
                ) : (
                    <>
                        <div className="k-flex-1 k-fs-sm">
                            <Skeleton shape="text" style={{ width: '95%' }} />
                            <Skeleton shape="text" style={{ width: '60%' }} />
                        </div>
                        <Skeleton className="k-rounded" shape="rectangle" style={{ width: 42, height: 18 }} />
                    </>
                )}
            </StackLayout>
        </div>
    );
}

export function IVASidePanel({ children }: { children?: React.ReactNode }) {
    return (
        <div className="k-p-4 k-pos-relative">
            <div className="k-border k-border-solid k-rounded-lg k-border-transparent ai-border k-pointer-events-none" />
            <AIAvatar />
            {children}
        </div>
    );
}

const SuggestionPager = ({
    currentPage,
    maxPages,
    onPageChange
}: {
    currentPage: number;
    maxPages: number;
    onPageChange: (beforeIdx: number, currentIdx: number) => void;
}) => {
    function onPageChangeHandler(newIdx: number) {
        if (newIdx < 1 || newIdx > maxPages) return;
        onPageChange(currentPage, newIdx);
    }

    return (
        <StackLayout orientation="horizontal" align={{ horizontal: 'start', vertical: 'middle' }} className="k-gap-1 k-fs-sm">
            <Button fillMode="flat" size="small" disabled={currentPage === 1} className="!k-min-w-0" onClick={() => onPageChangeHandler(currentPage - 1)}>
                <Icon kendoIconName="arrow-chevron-left" />
            </Button>
            {currentPage}
            <span>/</span>
            {maxPages}
            <Button
                fillMode="flat"
                size="small"
                disabled={currentPage === maxPages}
                className="!k-min-w-0"
                onClick={() => onPageChangeHandler(currentPage + 1)}
            >
                <Icon kendoIconName="arrow-chevron-right" />
            </Button>
        </StackLayout>
    );
};

export function IVAGuidancePanel({ guidanceItems }: { guidanceItems: string[] }) {
    return (
        <div className="k-mt-6">
            <H3 className="!k-mb-1">Guidance</H3>
            <ul className="k-fs-sm k-my-0 -last-child-mb-0 k-pl-5">
                {guidanceItems.map((guidanceItemText, guidanceItemIndex) => (
                    <li className="k-mb-1" key={guidanceItemIndex} dangerouslySetInnerHTML={{ __html: guidanceItemText }} />
                ))}
            </ul>
        </div>
    );
}
