import { Fade } from '@progress/kendo-react-animation';
import { Button, ButtonProps } from '@progress/kendo-react-buttons';
import { Skeleton } from '@progress/kendo-react-indicators';
import { StackLayout, Step, StepProps, Stepper, StepperChangeEvent } from '@progress/kendo-react-layout';
import { StepperContext } from '@progress/kendo-react-layout/dist/npm/stepper/context/StepperContext';
import { ReactNode, RefAttributes, forwardRef, useCallback, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { flushSync } from 'react-dom';
import { Link, To, useNavigate, useParams } from 'react-router-dom';
import { AIAvatar } from '../../components/ai/aiAvatar';
import { TaskEditorRef, normalizeEditorCommitResult } from '../../components/journey/editors';
import { TaskEditor } from '../../components/journey/editors/taskEditor';
import { TaskGuidance } from '../../components/journey/guidance';
import { TaskCompletionDialog } from '../../components/journey/taskCompletionDialog';
import { TaskGraphic } from '../../components/journey/taskGraphic';
import { TaskRelatedItemsView } from '../../components/journey/taskRelatedItemsView';
import { TaskTransitionDialog } from '../../components/journey/taskTransitionDialog';
import { TaskUnderConstructionView } from '../../components/journey/taskUnderConstruction';
import { ExpandCollapsibleBlock } from '../../components/ui/expandCollapsibleBlock';
import { TypeComponents } from '../../components/ui/typingComponents';
import { H2 } from '../../components/ui/typography';
import { appConfig } from '../../config';
import { useSingleClickButton } from '../../hooks/commonHooks';
import { useStartupLayout } from '../../hooks/startupHooks';
import { ReactComponent as ClockIcon } from '../../icons/clock.svg';
import { ReactComponent as ObjectiveIcon } from '../../icons/objective.svg';
import { googleTagManager } from '../../scripts/googleTagManager';
import { StickyElementObserver, StickyHeaderObserver, StickySideNavObserver } from '../../scripts/stickyElements';
import { Awaitable, combineClassNames, debounce, executeWithServerErrorInToast } from '../../services/common';
import { NotFoundException } from '../../services/httpServiceBase';
import {
    JourneyTask,
    JourneyTaskBody,
    JourneyTaskMarker,
    JourneyTaskStatus,
    JourneyTaskValidationSummary,
    MarkerTransitionWarning,
    journeyService
} from '../../services/journeyService';
import {
    RealTimeUpdateTaskMarkerEventData,
    RealTimeUpdateTaskSequenceEventData,
    RealTimeUpdateTaskStatusEventData,
    realTimeUpdatesEventHub
} from '../../services/realTimeUpdatesService';
import { UserRole } from '../../services/usersService';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { cleanRecentTask, setRecentTask, setRecentTasksSuppression } from '../../state/idea/recentTasksSlice';
import { addErrorNotification, addNotification, clearErrorNotifications } from '../../state/notifications/platformNotificationsSlice';
import { TaskData, TaskLoadExtension, taskLoadExtensions, useTaskRenderExtensions } from './extensibility/common';
import './extensibility/extensionsRegistry';

const stepRevealAnimationClassName = 'task-step-to-reveal';
type TransitionDialogData = {
    resultResolver: (result: boolean) => void;
    warning: MarkerTransitionWarning;
};

export const TaskPage = () => {
    const { ideaId, sequenceTag, taskTag, variationTag } = useTaskParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const currentUserId = useAppSelector(s => s.user?.userId);

    const [task, setTask] = useState<JourneyTask | undefined>();
    const [taskBody, rawSetTaskBody] = useState<JourneyTaskBody | undefined>();
    const setTaskBody = useCallback((taskBody: JourneyTaskBody | undefined) => {
        rawSetTaskBody(taskBody);
        if (taskBody) instantiatedLoadExtensionsRef.current?.forEach(extension => extension.onTaskBodyLoad(taskBody));
    }, []);
    const [journeyTaskMarker, setJourneyTaskMarker] = useState<JourneyTaskMarker | null>(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [showCompletionDialog, setShowCompletionDialog] = useState(false);
    const currentUserRole = useAppSelector(s => s.idea.role);
    const canEdit = !!currentUserRole && currentUserRole !== UserRole.Viewer;
    const [showTransitionDialog, setShowTransitionDialog] = useState(false);
    const transitionDialogData = useRef<TransitionDialogData>();
    const editorsCommitAwaitableRef = useRef<(Awaitable<boolean> | undefined)[]>([]);
    const [stepsValidationErrors, setStepsValidationErrors] = useState<Record<string, string[] | undefined>>();
    const [taskData, setTaskData] = useState<TaskData>({});

    const taskHeaderRef = useRef<HTMLElement>(null);
    const { pageContainerRef, setPageContainerClassName } = useStartupLayout();
    const sideNavRef = useRef<HTMLDivElement>(null);
    const sectionsRef = useRef<HTMLElement[]>([]);
    const sideNavObserverRef = useRef<StickySideNavObserver>();
    const taskFooterRef = useRef<HTMLElement>(null);
    const editorsRef = useRef<(TaskEditorRef | undefined | null)[]>([]);

    const [activeStepIndex, setActiveStepIndex] = useState(0);
    const maxActiveStepIndexRef = useRef(0);

    const ideaCurrentTasks = useAppSelector(s => (ideaId ? s.recentTasks.tasks[ideaId] : undefined));
    const isCurrentTaskRecent =
        !!taskTag &&
        !!ideaCurrentTasks &&
        ideaCurrentTasks.sequence === sequenceTag &&
        ideaCurrentTasks.tag === taskTag &&
        (!variationTag || variationTag === ideaCurrentTasks.variation);

    const instantiatedLoadExtensionsRef = useRef<TaskLoadExtension[]>();

    const isNewTask = (task: JourneyTask) => task.status === JourneyTaskStatus.New;
    const isWorkInProgressWithDifferentMarker = (task: JourneyTask, marker: JourneyTaskMarker | null) => {
        if (!marker || task.status !== JourneyTaskStatus.WorkInProgress) return false;

        return (
            marker.currentTaskTag !== task.tag ||
            marker.currentSequenceTag !== task.sequence ||
            (!!task.variation && marker.currentTaskVariationTag !== task.variation)
        );
    };
    const [goalTypingEnded, setGoalTypingEnded] = useState(false);
    const [typingSubtitleAtStep, setTypingSubtitleAtStep] = useState<number | undefined>(undefined);
    const [waitToShowEditorAtStep, setWaitToShowEditorAtStep] = useState<number | undefined>(undefined);

    const showTyping = !task || isNewTask(task) || isWorkInProgressWithDifferentMarker(task, journeyTaskMarker);
    useEffect(() => {
        const updateActiveStep = (element: HTMLElement | undefined) => {
            const newActiveStepIndex = element ? sectionsRef.current.indexOf(element) : 0;
            if (newActiveStepIndex <= maxActiveStepIndexRef.current) setActiveStepIndex(newActiveStepIndex === -1 ? 0 : newActiveStepIndex);
            else setActiveStepIndex(0);
        };

        const loadTaskData = async (updatePositionFromMarker: boolean = true) => {
            const task = await journeyService.getTask(ideaId, sequenceTag, taskTag, variationTag);

            if (cancelLoad) return;

            const taskBody = task.body;
            let currentUserTaskMarker: JourneyTaskMarker | null = null;
            if (task.status === JourneyTaskStatus.New) {
                maxActiveStepIndexRef.current = 0;
                setActiveStepIndex(0);
            } else {
                currentUserTaskMarker = await executeWithServerErrorInToast(() => journeyService.getCurrentUserTaskMarker(ideaId), 409);

                if (task.status === JourneyTaskStatus.Ready) maxActiveStepIndexRef.current = taskBody.pages.length;
            }

            setJourneyTaskMarker(currentUserTaskMarker);
            setIsLoaded(true);
            flushSync(() => {
                setTask(task);
                if (taskBody) setTaskBody(taskBody);
            });

            if (sideNavRef.current && pageContainerRef.current && !sideNavObserverRef.current) {
                sideNavObserverRef.current = sideNavObserver = new StickySideNavObserver(
                    sideNavRef.current,
                    pageContainerRef.current,
                    sectionsRef.current,
                    updateActiveStep,
                    taskHeaderRef.current
                );
            }

            if (taskFooterRef.current && pageContainerRef.current && !footerElementObserver) {
                footerElementObserver = new StickyElementObserver(taskFooterRef.current, pageContainerRef.current, 'sticky-footer-stuck', 'bottom');
                footerElementObserver.init();
            }

            if (task.status === JourneyTaskStatus.WorkInProgress && taskBody) {
                if (
                    currentUserTaskMarker &&
                    currentUserTaskMarker.currentSequenceTag === sequenceTag &&
                    currentUserTaskMarker.currentTaskTag === taskTag &&
                    (!variationTag || currentUserTaskMarker.currentTaskVariationTag === variationTag) &&
                    currentUserTaskMarker.currentPageTag
                ) {
                    if (updatePositionFromMarker) {
                        const currentUserPageIndex = taskBody.pages.findIndex(p => p.tag === currentUserTaskMarker!.currentPageTag);
                        if (currentUserPageIndex !== -1) {
                            const taskPageIndex = currentUserPageIndex + 1;
                            maxActiveStepIndexRef.current = taskPageIndex;
                            setActiveStepIndex(taskPageIndex);
                            scrollToStepAtIndex(taskPageIndex);
                            setTypingSubtitleAtStep(taskPageIndex);
                        }
                    } else {
                        const maxStepIndex = taskBody.pages.length;
                        if (maxActiveStepIndexRef.current > maxStepIndex) maxActiveStepIndexRef.current = maxStepIndex;
                        setActiveStepIndex(activeStepIndex => Math.min(activeStepIndex, maxStepIndex));
                    }
                } else {
                    maxActiveStepIndexRef.current = 0;
                    setActiveStepIndex(0);
                }
            }

            instantiatedLoadExtensionsRef.current?.forEach(extension => extension.onTaskLoad(task, taskBody));
        };

        let taskHeaderObserver: StickyHeaderObserver;
        if (taskHeaderRef.current && pageContainerRef.current) {
            taskHeaderObserver = new StickyHeaderObserver(taskHeaderRef.current, pageContainerRef.current, 'page-sticky-header-stuck');
        }

        let cancelLoad = false;
        let sideNavObserver: StickySideNavObserver;
        let footerElementObserver: StickyElementObserver;
        instantiatedLoadExtensionsRef.current = taskLoadExtensions.map(extension => new extension(ideaId, loadTaskData));
        loadTaskData();

        const loadTaskDataDebounced = debounce(loadTaskData, 500);

        const onTaskMarkerChanged = (e: RealTimeUpdateTaskMarkerEventData) => {
            if (e.ideaId !== ideaId || e.user !== currentUserId) return;

            loadTaskDataDebounced();
        };

        const onTaskStatusChanged = (e: RealTimeUpdateTaskStatusEventData) => {
            if (e.ideaId !== ideaId || e.sequence !== sequenceTag || e.task !== taskTag || (variationTag && e.variation !== variationTag)) return;

            loadTaskDataDebounced();
        };

        realTimeUpdatesEventHub.addEventListener('taskMarker', 'changed', onTaskMarkerChanged);
        realTimeUpdatesEventHub.addEventListener('task', 'statusChanged', onTaskStatusChanged);
        dispatch(setRecentTasksSuppression(true));

        return () => {
            cancelLoad = true;
            setTask(undefined);
            setTaskBody(undefined);
            setJourneyTaskMarker(null);
            setIsLoaded(false);
            setActiveStepIndex(0);
            maxActiveStepIndexRef.current = 0;
            taskHeaderObserver?.destroy();
            sideNavObserver?.destroy();
            footerElementObserver?.destroy();
            sideNavObserverRef.current = undefined;
            realTimeUpdatesEventHub.removeEventListener('taskMarker', 'changed', onTaskMarkerChanged);
            realTimeUpdatesEventHub.removeEventListener('task', 'statusChanged', onTaskStatusChanged);
            instantiatedLoadExtensionsRef.current?.forEach(extension => extension.onUnmount());
            instantiatedLoadExtensionsRef.current = undefined;
            dispatch(setRecentTasksSuppression(false));
        };
    }, [ideaId, pageContainerRef, currentUserId, dispatch, sequenceTag, taskTag, variationTag, setTaskBody]);

    useEffect(() => {
        if (!variationTag) return;

        function onSequenceVariationRemoved(e: RealTimeUpdateTaskSequenceEventData) {
            if (e.ideaId !== ideaId || e.sequence !== sequenceTag || e.variation !== variationTag) return;

            dispatch(addNotification({ content: 'Task variation was deleted' }));
            suppressRecentTask.current = true;
            navigate('../journey');
        }

        realTimeUpdatesEventHub.addEventListener('task', 'sequenceVariationRemoved', onSequenceVariationRemoved);

        return () => realTimeUpdatesEventHub.removeEventListener('task', 'sequenceVariationRemoved', onSequenceVariationRemoved);
    }, [dispatch, ideaId, navigate, sequenceTag, variationTag]);

    useTaskRenderExtensions(ideaId, task, taskBody, taskData, setTaskData);

    useEffect(() => {
        setPageContainerClassName('k-pt-4 k-px-0');

        return () => setPageContainerClassName(undefined);
    }, [setPageContainerClassName]);

    const scrollToStepAtIndex = (stepIndex: number) => {
        setActiveStepIndex(stepIndex);
        const stepElementToScrollTo = sectionsRef.current[stepIndex];

        if (stepElementToScrollTo && sideNavObserverRef.current) {
            sideNavObserverRef.current.scrollToTarget(stepElementToScrollTo);
        }
    };

    const scrollToStepAtIndexIfNeeded = (stepIndex: number) => {
        if (stepIndex === activeStepIndex) return;
        scrollToStepAtIndex(stepIndex);
    };

    const onStepperChange = (e: StepperChangeEvent) => {
        scrollToStepAtIndex(e.value);
    };

    const updateTaskStatus = async (newStatus: JourneyTaskStatus) => {
        const newTaskStatus = await journeyService.updateTaskStatus(ideaId, sequenceTag, taskTag, variationTag, newStatus);
        setTask(t => (t ? { ...t, status: newTaskStatus } : t));
    };

    const initTransitionDialog = (data: TransitionDialogData) => {
        if (transitionDialogData.current) transitionDialogData.current.resultResolver(false);

        transitionDialogData.current = data;
        setShowTransitionDialog(true);
    };

    const closeTransitionDialog = async (result: boolean) => {
        if (transitionDialogData.current) {
            transitionDialogData.current.resultResolver(result);
            transitionDialogData.current = undefined;
        }

        setShowTransitionDialog(false);
    };

    const confirmMarkerTransition = (): Promise<boolean> => {
        return new Promise<boolean>(async resolve => {
            const transitionWarning = await journeyService.validateMarkerTransition(ideaId, sequenceTag, taskTag, variationTag, undefined);
            if (!transitionWarning) resolve(true);
            else
                initTransitionDialog({
                    resultResolver: resolve,
                    warning: transitionWarning
                });
        });
    };

    const updateTaskMarker = async (taskStepIndex?: number): Promise<void> => {
        const pageTag = typeof taskStepIndex !== 'undefined' ? taskBody?.pages[taskStepIndex]?.tag : undefined;
        const updatedMarker = await journeyService.setCurrentUserTaskMarker(ideaId, sequenceTag, taskTag, variationTag, pageTag);
        setJourneyTaskMarker(updatedMarker);
    };

    const handleValidationResult = (validationResult: JourneyTaskValidationSummary) => {
        if (validationResult.isValid) {
            setStepsValidationErrors(undefined);
            return;
        }

        const taskErrorsGroupKey = 'task';
        let stepsValidationErrorsMap: Record<string, string[]> | undefined;
        for (const validationGroup in validationResult.results) {
            if (validationGroup === taskErrorsGroupKey || !Object.prototype.hasOwnProperty.call(validationResult.results, validationGroup)) continue;

            const groupValidationResults = validationResult.results[validationGroup];
            for (const groupValidationResult of groupValidationResults) {
                if (groupValidationResult.isValid) continue;
                if (!stepsValidationErrorsMap) stepsValidationErrorsMap = {};
                if (!stepsValidationErrorsMap[validationGroup]) stepsValidationErrorsMap[validationGroup] = [];
                stepsValidationErrorsMap[validationGroup].push(groupValidationResult.message);
            }
        }

        setStepsValidationErrors(stepsValidationErrorsMap);

        if (stepsValidationErrorsMap && taskBody) {
            const activePage = taskBody.pages[activeStepIndex - 1];
            // If the active page does not have error - scroll to the first page with error
            if (!activePage || !stepsValidationErrorsMap[activePage.tag]?.length) {
                const firstPageWithErrorIndex = taskBody.pages.findIndex(p => stepsValidationErrorsMap![p.tag]);
                if (firstPageWithErrorIndex !== -1) scrollToStepAtIndexIfNeeded(firstPageWithErrorIndex + 1);
            }
        }

        if (!stepsValidationErrorsMap) {
            // Show task errors only if there is no step errors
            const taskErrors: string[] | undefined = validationResult.results[taskErrorsGroupKey]?.filter(r => !r.isValid).map(r => r.message);
            if (taskErrors) {
                taskErrors.forEach(e => dispatch(addErrorNotification(e)));
            }
        }
    };

    const validateStep = async (stepIndex: number): Promise<boolean> => {
        if (!taskBody) throw new Error('Task body not set');

        const pageToValidate = taskBody.pages[stepIndex - 1];
        if (!pageToValidate) return true;

        const stepValidationResult = await journeyService.validateTaskPage(ideaId, sequenceTag, taskTag, variationTag, pageToValidate.tag);
        handleValidationResult(stepValidationResult);

        return stepValidationResult.isValid;
    };

    const validateTask = async (): Promise<boolean> => {
        const taskValidationResult = await journeyService.validateTask(ideaId, sequenceTag, taskTag, variationTag);
        handleValidationResult(taskValidationResult);

        return taskValidationResult.isValid;
    };

    const ensureEditorsCommit = async (): Promise<boolean> => {
        for (let editorIndex = 0; editorIndex <= maxActiveStepIndexRef.current; editorIndex++) {
            const editorRef = editorsRef.current[editorIndex];
            if (editorRef && editorRef.commit) {
                const commitResult = normalizeEditorCommitResult(editorRef.commit());
                if (!commitResult.isValid) {
                    if (!commitResult.preventScroll) scrollToStepAtIndexIfNeeded(editorIndex);
                    return false;
                }
            }
            const editorCommitAwaitable = editorsCommitAwaitableRef.current[editorIndex];
            if (editorCommitAwaitable) {
                const editorCommitResult = await editorCommitAwaitable.promise;
                if (!editorCommitResult) {
                    scrollToStepAtIndexIfNeeded(editorIndex);
                    return false;
                }
            }
        }

        return true;
    };

    const revealNextStep = async () => {
        if (!(await ensureEditorsCommit())) return;
        if (maxActiveStepIndexRef.current) {
            if (!(await validateStep(maxActiveStepIndexRef.current))) return;
        } else if (!(await confirmMarkerTransition())) return;

        if (task?.status === JourneyTaskStatus.New) await updateTaskStatus(JourneyTaskStatus.WorkInProgress);

        await updateTaskMarker(maxActiveStepIndexRef.current);

        maxActiveStepIndexRef.current++;
        setActiveStepIndex(maxActiveStepIndexRef.current);
        setTypingSubtitleAtStep(maxActiveStepIndexRef.current);
        const stepElementToReveal = sectionsRef.current[maxActiveStepIndexRef.current];
        if (stepElementToReveal) {
            stepElementToReveal.classList.add(stepRevealAnimationClassName);
            window.requestAnimationFrame(() => {
                stepElementToReveal.classList.remove(stepRevealAnimationClassName);
                scrollToStepAtIndex(maxActiveStepIndexRef.current);
            });
        }
    };

    const closeTask = async () => {
        if (!(await ensureEditorsCommit())) return;
        const isTaskValid = await validateTask();
        if (!isTaskValid) return;

        await updateTaskMarker();
        if (task?.status === JourneyTaskStatus.Ready) {
            if (pageContainerRef.current) pageContainerRef.current.scrollTop = 0;
            window.requestAnimationFrame(() => navigate(`../${appConfig.startup.defaultScreenUrl}`));
        } else {
            await updateTaskStatus(JourneyTaskStatus.Ready);
            setShowCompletionDialog(true);
            if (task) googleTagManager.reportTaskCompletedEvent(task.index);
        }

        suppressRecentTask.current = true;
    };

    const startEditTask = async () => {
        if (!(await confirmMarkerTransition())) return;
        await updateTaskMarker();
    };

    const clearErrors = (pageTag: string) => {
        dispatch(clearErrorNotifications());
        if (stepsValidationErrors && stepsValidationErrors[pageTag]) {
            // Remove errors for the current page
            const { [pageTag]: _, ...updatedStepsValidationErrors } = stepsValidationErrors;
            setStepsValidationErrors(updatedStepsValidationErrors);
        }
    };

    const onBeginCommitEditor = (stepIndex: number) => {
        if (!taskBody) throw new Error('Task body not set');
        const pageTag = taskBody.pages[stepIndex - 1].tag;
        clearErrors(pageTag);
        const editorCommitAwaitable = editorsCommitAwaitableRef.current[stepIndex];
        if (editorCommitAwaitable) editorCommitAwaitable.resolve(false);
        editorsCommitAwaitableRef.current[stepIndex] = new Awaitable<boolean>();
    };

    const onEndCommitEditor = (stepIndex: number, isValid?: boolean) => {
        const editorCommitAwaitable = editorsCommitAwaitableRef.current[stepIndex];
        if (!editorCommitAwaitable) return;
        editorCommitAwaitable.resolve(isValid !== false);
        editorsCommitAwaitableRef.current[stepIndex] = undefined;
    };

    const getStepProps = (stepIndex: number, title: string, canShowButton: boolean = true): TaskStepProps & RefAttributes<HTMLElement> => {
        const isFinalStep = taskBody && taskBody.pages.length === stepIndex;
        return {
            stepName: stepIndex === 0 ? 'Goal' : `Step ${stepIndex}`,
            highlightTitle: stepIndex === activeStepIndex,
            isHidden: stepIndex > maxActiveStepIndexRef.current,
            showButton: canShowButton && canEdit && (task && task.status === JourneyTaskStatus.Ready ? false : stepIndex === maxActiveStepIndexRef.current),
            buttonText: task ? (isFinalStep ? "I'm ready with this task" : stepIndex === 0 ? "Let's go!" : "I'm ready with this step") : '',
            buttonColor: isFinalStep ? 'primary' : 'secondary',
            buttonFill: isFinalStep ? 'solid' : 'outline',
            style:
                stepIndex === maxActiveStepIndexRef.current && pageContainerRef.current && taskHeaderRef.current
                    ? {
                          minHeight:
                              pageContainerRef.current.clientHeight -
                              taskHeaderRef.current.clientHeight -
                              (taskFooterRef.current ? taskFooterRef.current.clientHeight : 0) -
                              48
                      } // subtract the padding bottom of the pageContainer (24px) + the padding top of the page container (16px) plus the 8px offset that is added when we are scrolling to the item
                    : undefined,
            onComplete: task ? (isFinalStep ? closeTask : revealNextStep) : undefined,
            title: title,
            ref: r => {
                if (r) sectionsRef.current[stepIndex] = r;
            }
        };
    };

    const isEditing =
        canEdit &&
        !!task &&
        (task.status !== JourneyTaskStatus.Ready ||
            (!!journeyTaskMarker &&
                journeyTaskMarker.currentSequenceTag === sequenceTag &&
                journeyTaskMarker.currentTaskTag === taskTag &&
                (!variationTag || journeyTaskMarker.currentTaskVariationTag === variationTag)));

    const isUnmounted = useRef(false);
    const isMarkAsRecentLoadExecuted = useRef(false);
    useEffect(() => {
        if (!ideaId || !isLoaded || isUnmounted.current || isMarkAsRecentLoadExecuted.current) return;

        isMarkAsRecentLoadExecuted.current = true;
        if (isCurrentTaskRecent) dispatch(cleanRecentTask(ideaId));
    }, [ideaId, isLoaded, isCurrentTaskRecent, dispatch]);

    useEffect(() => {
        isUnmounted.current = false;

        return () => {
            isUnmounted.current = true;
        };
    }, []);

    const suppressRecentTask = useRef(false);
    useEffect(
        () => () => {
            if (!isUnmounted.current || !ideaId || suppressRecentTask.current) return;

            if (isEditing && task.status !== JourneyTaskStatus.New)
                dispatch(setRecentTask({ ideaId: ideaId, task: { index: task.index, sequence: task.sequence, tag: task.tag, variation: variationTag } }));
        },
        [ideaId, task, isEditing, dispatch, variationTag]
    );

    return (
        <>
            <header ref={taskHeaderRef} className="task-header k-gap-2 page-sticky-header k-px-6 k-pt-4 k-pb-5">
                <div>
                    <Link
                        to="../journey"
                        onClick={() => (suppressRecentTask.current = true)}
                        className="k-button k-button-link k-fs-sm k-button-link-base k-font-weight-semibold k-mt-thin"
                    >
                        <span className="k-icon k-i-arrow-chevron-left"></span> Back to journey
                    </Link>
                </div>
                <H2 className="k-text-center">
                    {task ? (
                        <>
                            Task {task.index}: {task.title}
                        </>
                    ) : (
                        <Skeleton shape="text" style={{ width: '60%', margin: '0 auto' }} />
                    )}
                </H2>
            </header>

            <div className="k-px-6">
                <div className="page-content-middle task-content-wrapper">
                    <StackLayout align={{ horizontal: 'center', vertical: 'stretch' }} className="k-gap-16">
                        <div className="k-flex-1 k-min-w-0">
                            <TaskStep {...getStepProps(0, 'What is the goal of this task?', !showTyping || goalTypingEnded)}>
                                <TaskGoalSection
                                    task={task}
                                    showTyping={showTyping}
                                    typingEnded={goalTypingEnded}
                                    onTypingEnded={() => setGoalTypingEnded(true)}
                                />
                                {task?.graphic && <TaskGraphic name={task.graphic} className="k-mt-8" />}
                            </TaskStep>
                            {taskBody &&
                                taskBody.pages.map((page, pageIndex) => {
                                    const stepIndex = pageIndex + 1;
                                    const stepProps = getStepProps(stepIndex, page.title);
                                    const isTypingSubtitle = typingSubtitleAtStep !== undefined && typingSubtitleAtStep === stepIndex;
                                    const isReadyToShowCurrentEditor = waitToShowEditorAtStep === undefined || waitToShowEditorAtStep !== stepIndex;
                                    stepProps.showButton = stepProps.showButton && !isTypingSubtitle && isReadyToShowCurrentEditor;
                                    return (
                                        <TaskStep key={pageIndex} {...stepProps} isUnderConstruction={appConfig.underConstructionTaskIndex === task?.index}>
                                            <StackLayout orientation="vertical" className="k-gap-6">
                                                <StackLayout align={{ horizontal: 'start', vertical: 'top' }} className="k-gap-3">
                                                    <AIAvatar className="k-flex-shrink-0" animate={isTypingSubtitle} />
                                                    <div>
                                                        <TaskSubtitleSection
                                                            className="k-mt-2.5"
                                                            subtitle={page.subtitle}
                                                            delayMs={800}
                                                            isTyping={typingSubtitleAtStep === stepIndex}
                                                            onTypingEnded={() => {
                                                                if (page.relatedItems) {
                                                                    setWaitToShowEditorAtStep(stepIndex);
                                                                }
                                                                setTypingSubtitleAtStep(undefined);
                                                            }}
                                                        />
                                                        {page.relatedItems && (
                                                            <Fade
                                                                className="k-d-block"
                                                                transitionEnterDuration={100}
                                                                onEntered={() => {
                                                                    setWaitToShowEditorAtStep(undefined);
                                                                }}
                                                            >
                                                                {!isTypingSubtitle && <TaskRelatedItemsView items={page.relatedItems} />}
                                                            </Fade>
                                                        )}
                                                    </div>
                                                </StackLayout>
                                                {page.guidanceRef && taskBody.guidance && (
                                                    <Fade className="k-d-block">
                                                        {!isTypingSubtitle && isReadyToShowCurrentEditor && (
                                                            <ExpandCollapsibleBlock
                                                                className="k-icp-panel k-icp-panel-secondary !k-border-none"
                                                                enabled={!!page.readingOptional}
                                                                collapsed={page.readingOptional}
                                                            >
                                                                <TaskGuidance guidanceRef={page.guidanceRef} availableGuidance={taskBody.guidance} />
                                                            </ExpandCollapsibleBlock>
                                                        )}
                                                    </Fade>
                                                )}
                                                <Fade className="k-d-block k-overflow-visible">
                                                    {!isTypingSubtitle && page.editor && isReadyToShowCurrentEditor && (
                                                        <TaskEditor
                                                            ref={r => {
                                                                editorsRef.current[pageIndex + 1] = r;
                                                            }}
                                                            ideaId={ideaId}
                                                            editor={page.editor}
                                                            isEditing={isEditing}
                                                            isHidden={stepProps.isHidden}
                                                            onBeginCommit={() => onBeginCommitEditor(pageIndex + 1)}
                                                            onEndCommit={isValid => onEndCommitEditor(pageIndex + 1, isValid)}
                                                            errors={stepsValidationErrors?.[page.tag]}
                                                            navigate={(to: To) => {
                                                                if (ideaId && task)
                                                                    dispatch(
                                                                        setRecentTask({
                                                                            ideaId: ideaId,
                                                                            task: {
                                                                                index: task.index,
                                                                                sequence: task.sequence,
                                                                                tag: task.tag,
                                                                                variation: variationTag
                                                                            }
                                                                        })
                                                                    );
                                                                navigate(to);
                                                            }}
                                                            guidance={taskBody.guidance}
                                                            taskData={taskData}
                                                            setTaskData={setTaskData}
                                                        />
                                                    )}
                                                </Fade>
                                            </StackLayout>
                                        </TaskStep>
                                    );
                                })}
                        </div>
                        <aside>
                            {task && (
                                <div ref={sideNavRef} className="task-steps-nav k-pt-2 k-mt-4">
                                    <Stepper
                                        item={TaskStepperStep}
                                        items={new Array(taskBody ? taskBody.pages.length + 1 : 1).fill(undefined).map((_, i) => ({
                                            disabled: i > maxActiveStepIndexRef.current,
                                            children: i === 0 ? <ObjectiveIcon className="k-icp-icon k-icp-icon-size-4" /> : undefined
                                        }))}
                                        value={activeStepIndex}
                                        onChange={onStepperChange}
                                        orientation="vertical"
                                        className="k-icp-stepper-with-progress-hint"
                                    />
                                </div>
                            )}
                        </aside>
                    </StackLayout>
                </div>
            </div>

            {canEdit && task && task.status === JourneyTaskStatus.Ready && (
                <footer ref={taskFooterRef} className="sticky-footer task-footer k-px-6 k-py-3 k-text-center">
                    {isEditing ? (
                        <Button onClick={closeTask} themeColor="primary" size="large">
                            I’m ready with this task
                        </Button>
                    ) : (
                        <Button onClick={startEditTask} fillMode="outline" themeColor="secondary" size="large">
                            Edit this task
                        </Button>
                    )}
                </footer>
            )}

            {taskBody && showCompletionDialog && <TaskCompletionDialog taskCompletion={taskBody.completion} onClose={() => setShowCompletionDialog(false)} />}
            {showTransitionDialog && transitionDialogData.current && (
                <TaskTransitionDialog
                    onConfirm={() => closeTransitionDialog(true)}
                    onCancel={() => closeTransitionDialog(false)}
                    warning={transitionDialogData.current.warning}
                />
            )}
        </>
    );
};

const TaskStepperStep = (props: StepProps) => {
    const stepperContext = useContext(StepperContext);

    return (
        <Step {...props}>
            <span className="k-step-indicator" aria-hidden="true" style={{ transitionDuration: stepperContext.animationDuration + 'ms' }}>
                <span className="k-step-indicator-text">{props.children ? props.children : props.text ? props.text : props.index ?? 0 + 1}</span>
            </span>
        </Step>
    );
};

type TaskStepProps = {
    stepName?: string;
    title: string;
    highlightTitle: boolean;
    showButton: boolean;
    buttonText: string;
    buttonColor: ButtonProps['themeColor'];
    buttonFill: ButtonProps['fillMode'];
    isHidden: boolean;
    style?: React.CSSProperties;
    onComplete: (() => void) | undefined;
    children?: ReactNode;
    isUnderConstruction?: boolean;
};

const TaskStep = forwardRef<HTMLElement, TaskStepProps>(
    ({ stepName, title, highlightTitle, showButton, buttonText, buttonColor, buttonFill, isHidden, style, onComplete, children, isUnderConstruction }, ref) => {
        const [buttonDisabled, singleClickActionCreator] = useSingleClickButton();
        const className = combineClassNames('task-step', isHidden ? 'task-step-hidden' : undefined);
        const buttonRef = useRef<Button>(null);

        // set the element disabled property instead of setting the disabled property of the Button component since otherwise for some reason this breaks the scroll to the first invalid step under Chrome
        useLayoutEffect(() => {
            if (!buttonRef.current?.element) return;

            buttonRef.current.element.disabled = buttonDisabled;
        }, [buttonDisabled]);

        if (isUnderConstruction)
            return (
                <section ref={ref} className={className} style={style}>
                    <TaskUnderConstructionView />
                </section>
            );

        return (
            <section ref={ref} className={className} style={style}>
                <div className="k-fs-sm k-text-uppercase">{stepName}</div>
                <div className={combineClassNames('k-display-4 k-mb-8', highlightTitle ? 'k-text-secondary' : undefined)}>{title}</div>
                <div className="k-mb-10">{children}</div>
                <Fade className="k-d-block k-z-0">
                    {showButton && (
                        <div className="k-text-center">
                            {buttonText ? (
                                <Button
                                    ref={buttonRef}
                                    size="large"
                                    themeColor={buttonColor}
                                    fillMode={buttonFill}
                                    onClick={onComplete ? singleClickActionCreator(onComplete) : undefined}
                                >
                                    {buttonText}
                                </Button>
                            ) : (
                                <Skeleton shape="rectangle" style={{ width: 100, height: 42 }} className="-block-center" />
                            )}
                        </div>
                    )}
                </Fade>
            </section>
        );
    }
);

export function useTaskParams() {
    const { ideaId, sequenceTag, taskTag, variationTag } = useParams();
    if (!ideaId || !sequenceTag || !taskTag) throw new NotFoundException();

    return { ideaId, sequenceTag, taskTag, variationTag: variationTag || undefined };
}

interface TaskGoalSectionProps {
    task: JourneyTask | undefined;
    showTyping: boolean;
    typingEnded: boolean;
    onTypingEnded: () => void;
}

type ParsedContent = (string | JSX.Element)[];

function parseContentWithGloassaryItems(text: string): ParsedContent {
    const parts: ParsedContent = [];
    let currentIndex = 0;

    // Regular expression to match <dfn> tags with their content
    const dfnRegex = /<dfn data-term-id='([^']+)'>([^<]+)<\/dfn>/g;

    let match;
    while ((match = dfnRegex.exec(text)) !== null) {
        // Add text before the <dfn> tag
        if (match.index > currentIndex) {
            parts.push(text.substring(currentIndex, match.index));
        }

        // Add the <dfn> tag as a React component
        const [, termId, content] = match;
        parts.push(
            <dfn key={`${termId}-${match.index}`} data-term-id={termId}>
                {content}
            </dfn>
        );

        currentIndex = match.index + match[0].length;
    }

    // Add any remaining text after the last <dfn> tag
    if (currentIndex < text.length) {
        parts.push(text.substring(currentIndex));
    }

    return parts;
}

const TaskGoalSection = ({ task, showTyping, typingEnded, onTypingEnded }: TaskGoalSectionProps) => {
    if (!task) {
        return (
            <>
                <Skeleton shape="text" style={{ width: '100%' }} />
                <Skeleton shape="text" style={{ width: '60%' }} />
            </>
        );
    }

    const isTyping = showTyping && !typingEnded;

    return (
        <StackLayout align={{ horizontal: 'start', vertical: 'stretch' }}>
            <AIAvatar animate={isTyping} className="k-mr-3" />
            {isTyping ? (
                <span className="k-flex-1 k-pr-6 k-mt-2.5">
                    <TypeComponents
                        onTypeEnd={() => {
                            onTypingEnded();
                        }}
                    >
                        {parseContentWithGloassaryItems(task.goal)}
                    </TypeComponents>
                </span>
            ) : (
                <span dangerouslySetInnerHTML={{ __html: task.goal }} className="k-flex-1 k-pr-6 k-mt-2.5" />
            )}

            <div className="k-separator k-separator-darker" style={{ visibility: isTyping ? 'hidden' : 'visible' }} />
            <StackLayout
                orientation="vertical"
                style={{ visibility: isTyping ? 'hidden' : 'visible' }}
                align={{ horizontal: 'start', vertical: 'middle' }}
                className="k-pl-4 k-py-1"
            >
                <div className="k-fs-xs">Approx. time</div>
                <StackLayout align={{ horizontal: 'start', vertical: 'middle' }} gap={6}>
                    <ClockIcon className="k-icp-icon k-icp-icon-size-4" />
                    {task ? task.duration : <Skeleton shape="text" style={{ width: '44px' }} />}
                </StackLayout>
            </StackLayout>
        </StackLayout>
    );
};

interface TaskSubtitleSectionProps {
    subtitle: string;
    className?: string;
    delayMs?: number;
    isTyping: boolean;
    onTypingEnded: () => void;
}

const TaskSubtitleSection = ({ subtitle, className, delayMs, isTyping, onTypingEnded }: TaskSubtitleSectionProps) => {
    return (
        <>
            {isTyping ? (
                <div className={className}>
                    <TypeComponents
                        initialDelayMs={delayMs}
                        onTypeEnd={() => {
                            onTypingEnded();
                        }}
                    >
                        {parseContentWithGloassaryItems(subtitle)}
                    </TypeComponents>
                </div>
            ) : (
                <div dangerouslySetInnerHTML={{ __html: subtitle }} className={className} />
            )}
        </>
    );
};
