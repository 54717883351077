import { StackLayout } from '@progress/kendo-react-layout';
import { useState } from 'react';
import { TaskEditorProps } from '.';
import { useGlobalCanvas } from '../../../hooks/canvasHooks';
import customerSegmentsIllustrationUrl from '../../../images/customer-segments-illustration.svg';
import { canvasService } from '../../../services/canvasService';
import { GraphicData, combineClassNames } from '../../../services/common';
import { CanvasBoxEditorParams, CanvasBoxEditorParamsWithGuidance, JourneyTaskGuidanceHints } from '../../../services/journeyService';
import CanvasBox from '../../canvas/box';
import { HintsDefaultLayout } from '../../common/Hints';
import LoadingIndicator from '../../ui/loadingIndicator';
import { EditorErrorsList } from './shared/editorErrorsList';
import { EditorMainArea } from './shared/editorMainArea';
import { EditorTwoColumnLayout, EditorTwoColumnLayoutMode } from './shared/editorTwoColumnLayout';
import { IVAGuidancePanel, IVASidePanel, IVASuggestionsPanel } from './shared/IVASuggestions';
import { RelatedCanvasBoxes } from './shared/relatedCanvasBoxes';

const boxIllustrationsUrls: Partial<Record<string, GraphicData>> = {
    'customer-segments-illustration': { url: customerSegmentsIllustrationUrl, width: 248, height: 293, description: '' }
};

export function CanvasBoxEditor(props: TaskEditorProps<CanvasBoxEditorParams>) {
    const { canvas, relatableItems } = useGlobalCanvas();
    const [editContent, setEditContent] = useState<string | undefined>(undefined);

    const box = canvas.boxes?.find(b => b.type === props.params.boxType);
    const showErrors = props.isEditing && !!props.errors?.length;

    const hints =
        props.params.hintsRef && props.guidance ? props.guidance.find((g): g is JourneyTaskGuidanceHints => g.id === props.params.hintsRef) : undefined;

    const guidance =
        props.params.type === 'editor-with-guidance' || props.params.type === 'editor-with-boxes-and-guidance'
            ? props?.guidance?.find(g => g.id === (props.params as CanvasBoxEditorParamsWithGuidance).guidanceRef)
            : undefined;

    const useIvaValidationHints = props.params.type === 'editor-with-guidance' || props.params.type === 'editor-with-boxes-and-guidance';

    return (
        <EditorTwoColumnLayout
            firstColumn={
                <EditorMainArea className="-h100">
                    <StackLayout orientation="vertical" align={{ horizontal: 'stretch', vertical: 'top' }} className="-h100">
                        {box ? (
                            <CanvasBox
                                box={box}
                                relatableItems={relatableItems}
                                canEdit={props.isEditing}
                                editContent={editContent}
                                onCancel={() => setEditContent(undefined)}
                                onSave={() => setEditContent(undefined)}
                                className={combineClassNames('canvas-box-solo canvas-box-in-editor k-flex-1', showErrors ? '!k-border-error' : undefined)}
                                autoHideInsertControls={false}
                                itemHints={hints && <HintsDefaultLayout hints={hints.items} title={hints.title} />}
                                ivaValidationHints={useIvaValidationHints ? hints : undefined}
                                hintsValidationFunction={async (text, signal) =>
                                    (await canvasService.validateItem(props.ideaId, box.type, text, signal)).results
                                }
                                onBeginCommit={props.onBeginCommit}
                                onEndCommit={props.onEndCommit}
                                requireRelatedItems={true}
                                mainRelatedItemsIds={props.params.relatedItems?.length ? props.params.relatedItems.map(i => i.itemId) : undefined}
                            />
                        ) : (
                            <LoadingIndicator size="big" className="k-display-block -block-center" />
                        )}
                        <EditorErrorsList isEditing={props.isEditing} errors={props.errors} />
                    </StackLayout>
                </EditorMainArea>
            }
            secondColumn={
                props.params.type === 'editor-with-boxes' ? (
                    <RelatedCanvasBoxes
                        boxes={props.params.secondaryBoxes}
                        navigate={props.navigate}
                        showItemsOrRelatedItemsTo={props.params.relatedItems}
                        pointToBoxOnNavigate={props.params.boxType}
                    />
                ) : props.params.type === 'editor-with-graphic' ? (
                    <img
                        src={boxIllustrationsUrls[props.params.graphic]!.url}
                        width={boxIllustrationsUrls[props.params.graphic]!.width}
                        height={boxIllustrationsUrls[props.params.graphic]!.height}
                        alt={boxIllustrationsUrls[props.params.graphic]!.description}
                        className="k-mx-8"
                    />
                ) : props.params.type === 'editor-with-guidance' && guidance ? (
                    <IVASidePanel>
                        <IVASuggestionsPanel
                            shouldLoadSuggestions={!props.isHidden}
                            ideaId={props.ideaId}
                            boxType={props.params.boxType}
                            disabled={!props.isEditing}
                            onPickSuggestion={(suggestion: string) => {
                                setEditContent(suggestion);
                            }}
                        />
                        <IVAGuidancePanel guidanceItems={guidance.items} />
                    </IVASidePanel>
                ) : props.params.type === 'editor-with-boxes-and-guidance' && guidance ? (
                    <IVASidePanel>
                        <IVASuggestionsPanel
                            shouldLoadSuggestions={!props.isHidden}
                            ideaId={props.ideaId}
                            boxType={props.params.boxType}
                            disabled={!props.isEditing}
                            onPickSuggestion={(suggestion: string) => {
                                setEditContent(suggestion);
                            }}
                        />
                        <IVAGuidancePanel guidanceItems={guidance.items} />
                        <RelatedCanvasBoxes
                            boxes={props.params.secondaryBoxes}
                            navigate={props.navigate}
                            showItemsOrRelatedItemsTo={props.params.relatedItems}
                            pointToBoxOnNavigate={props.params.boxType}
                            variant="panel"
                        />
                    </IVASidePanel>
                ) : (
                    undefined
                )
            }
            mode={
                props.params.type === 'editor-with-boxes' ||
                props.params.type === 'editor-with-guidance' ||
                props.params.type === 'editor-with-boxes-and-guidance'
                    ? EditorTwoColumnLayoutMode.EqualColumns
                    : EditorTwoColumnLayoutMode.FirstMainColumn
            }
        />
    );
}
