import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import CanvasBox from '../../components/canvas/box';
import { CanvasDependenciesFromQueryStringVisualization } from '../../components/canvas/canvasDependenciesVisualization';
import LoadingIndicator from '../../components/ui/loadingIndicator';
import { H1 } from '../../components/ui/typography';
import { useGlobalCanvas } from '../../hooks/canvasHooks';
import { useHighlight } from '../../hooks/highlightsHooks';
import { BoxType, canvasService, IvaValidationHints } from '../../services/canvasService';
import { JourneyTaskNavigationHighlight } from '../../services/journeyService';
import { UserRole } from '../../services/usersService';
import { useAppSelector } from '../../state/hooks';

export default function CanvasPage() {
    const { ideaId } = useParams();
    const { canvas, relatableItems } = useGlobalCanvas(ideaId);
    const currentRole = useAppSelector(s => s.idea.role);

    const canEditCanvas = currentRole !== UserRole.Viewer;
    const canvasElementRef = useRef<HTMLDivElement>(null);
    const setShowHighlight = useHighlight(canvasElementRef, JourneyTaskNavigationHighlight.FocusCanvas);
    const [ivaValidationHints, setIvaValidationHints] = useState<Record<BoxType, IvaValidationHints> | undefined>(undefined);

    const showCanvas = !!ideaId && !!canvas.boxes && !!currentRole;

    useEffect(() => {
        setShowHighlight(showCanvas);
    }, [showCanvas, setShowHighlight]);

    useEffect(() => {
        if (showCanvas) {
            const taskRelationsPromise = canvasService.getTaskRelations(ideaId);
            const hintsPromise = canvasService.getAllHints(ideaId);

            Promise.all([taskRelationsPromise, hintsPromise]).then(([taskRelations, hints]) => {
                const hintsRecord = hints.boxHints.reduce((acc, { box, hints, title }) => {
                    const relatedTaskData = taskRelations.boxTaskRelations.find(tr => tr.box === box)?.relation;
                    acc[box] = { relatedTaskData, items: hints, title };
                    return acc;
                }, {} as Record<BoxType, IvaValidationHints>);

                setIvaValidationHints(hintsRecord);
            });
        }
    }, [ideaId, showCanvas]);

    return (
        <div className="canvas-page highlight-target-inset">
            <H1 className="heading-row">Canvas</H1>
            {!showCanvas || !ivaValidationHints ? (
                <LoadingIndicator size="big" className="k-flex-1 k-align-self-center" />
            ) : (
                <div ref={canvasElementRef} className="idea-canvas k-pos-relative">
                    {canvas.boxes &&
                        canvas.boxes.map(box => {
                            return (
                                <CanvasBox
                                    ivaValidationHints={ivaValidationHints?.[box.type]}
                                    hintsValidationFunction={async (text, signal) => {
                                        const result = await canvasService.validateItem(ideaId, box.type, text, signal);
                                        return result.results;
                                    }}
                                    key={box.type}
                                    box={box}
                                    relatableItems={relatableItems}
                                    canEdit={canEditCanvas}
                                />
                            );
                        })}

                    <CanvasDependenciesFromQueryStringVisualization canvasElementRef={canvasElementRef} />
                </div>
            )}
        </div>
    );
}
